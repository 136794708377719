import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CarCharge, CarDocument } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addCarDocument(
  carId: string,
  charge: CarCharge,
  invoice: File | null,
): Promise<CarDocument> {
  const form = new FormData();

  if (invoice) {
    form.append("invoice", invoice);
  } else {
    form.append("no-file", "true");
  }

  return axiosInstance.post(`/cars/${carId}/charges`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: charge,
  });
}

interface Props {
  charge: CarCharge;
  invoice: File | null;
}

export function useAddCarCharge(
  carId: string,
): UseMutateAsyncFunction<CarDocument, unknown, Props, unknown> {
  const { mutateAsync } = useMutation(({ charge, invoice }: Props) =>
    addCarDocument(carId, charge, invoice),
  );

  return mutateAsync;
}
