import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { MonthlyStats } from "../../../../types/common.types";

async function getStats(period: string): Promise<MonthlyStats> {
  const { data } = await axiosInstance.get(`/stats/monthly`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      period,
    },
  });

  return data;
}

interface UseMonthlyStats {
  isLoading: boolean;
  isFetching: boolean;
  monthlyStats: MonthlyStats | null;
}

export function useMonthlyStats(period: string): UseMonthlyStats {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.stats, "monthly", period],
    () => getStats(period),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    monthlyStats: data || null,
  };
}
