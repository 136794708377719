import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Card,
} from "@shopify/polaris";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Agent } from "../../../types/common.types";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useAgent } from "./hooks/useAgent";
import AgentDetails from "./components/AgentDetails";
import AgentPayments from "./components/AgentPayments/AgentPayments";

export default function AgentPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const { agentId } = useParams();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { agent, isLoading, isError } = useAgent(agentId as string);

  // const deleteCars = useDeleteCars();

  const handleDeleteCar = async () => {
    setDeleteLoading(false);

    try {
      // await deleteCars([carId as string]);

      onShowToast("Car Deleted", false);

      navigate("/admin/agents");

      await queryClient.invalidateQueries([queryKeysConstants.agents]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }
  };

  if (isError) {
    navigate("/admin/agents");
  }

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      fullWidth
      title={agent?.name}
      backAction={{
        onAction: () => navigate("/admin/agents"),
      }}
      primaryAction={{
        content: t("delete"),
        destructive: true,
        onAction: handleDeleteCar,
        loading: deleteLoading,
      }}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <AgentDetails agent={agent as Agent} />
        </Layout.Section>
        <Layout.Section variant="fullWidth">
          <AgentPayments agentId={(agent as Agent).id as string} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
