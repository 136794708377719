import moment from "moment";
import "moment/locale/fr";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatMoney(value: number): string {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "MAD",
  }).format(value);
}

export function formatDate(
  value: Date | undefined,
  onlyDate = false,
  simpleFormat = false,
  forceFromNow = false,
): string {
  if (forceFromNow || (moment.utc(value) > moment() && !simpleFormat)) {
    return capitalizeFirstLetter(moment.utc(value).locale("fr-FR").fromNow());
  }

  let format = "MMMM Do YYYY, HH:mm";

  if (onlyDate) {
    format = "MMMM Do YYYY";
  }

  return capitalizeFirstLetter(
    moment.utc(value).locale("fr-FR").format(format),
  );
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + parseInt(days.toString(), 10));

  return result;
}

export function timezoneOffsetDate(d: Date): Date {
  const offset = new Date().getTimezoneOffset();

  return new Date(d.getTime() - offset * 60 * 1000);
}

export function getTimeString(d: Date): string {
  const date = timezoneOffsetDate(d);

  const dateSplit = date.toISOString().split("T")[1].split(":");
  return `${dateSplit[0]}:${dateSplit[1]}`;
}

export function getTwoDatesDaysDifference(date1: Date, date2: Date): number {
  const differenceInTime = date1.getTime() - date2.getTime();

  return Math.round(differenceInTime / (1000 * 3600 * 24));
}

export function isEmpty(value: string | any[]) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === "" || value == null;
}
