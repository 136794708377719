import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Car } from "../../../../types/common.types";

async function updateCar(carImage: File | null, car: Car): Promise<Car> {
  const form = new FormData();

  if (carImage) {
    form.append("file", carImage);
  }

  form.append("padding", "true");

  return axiosInstance.put(`/cars/${car.id}`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: car,
  });
}

interface Props {
  carImage: File | null;
  car: Car;
}

export function useUpdateCar(): UseMutateAsyncFunction<
  Car,
  unknown,
  Props,
  unknown
> {
  const { mutateAsync } = useMutation(({ carImage, car }: Props) =>
    updateCar(carImage, car),
  );

  return mutateAsync;
}
