import logoPng from "../static/images/logo512.png";

const logo = {
  width: 100,
  topBarSource: logoPng,
  contextualSaveBarSource: logoPng,
  url: "/",
  accessibilityLabel: "RoveRide Rentals",
};

export default logo;
