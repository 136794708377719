import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { Config } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function updateConfig(config: Config): Promise<Config> {
  return axiosInstance.put(`/config/`, config, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateConfig(): UseMutateAsyncFunction<
  Config,
  unknown,
  Config,
  unknown
> {
  const { mutateAsync } = useMutation((config: Config) => updateConfig(config));

  return mutateAsync;
}
