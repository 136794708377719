import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { AgentPayment } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addAgentPayment(
  agentId: string,
  payment: AgentPayment,
): Promise<AgentPayment> {
  return axiosInstance.post(`/agents/${agentId}/payments`, payment, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}
export function useAddAgentPayment(
  agentId: string,
): UseMutateAsyncFunction<AgentPayment, unknown, AgentPayment, unknown> {
  const { mutateAsync } = useMutation((payment: AgentPayment) =>
    addAgentPayment(agentId, payment),
  );

  return mutateAsync;
}
