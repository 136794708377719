import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteCharges(carId: string, ids: string[]): Promise<void> {
  await axiosInstance.delete(`/cars/${carId}/charges?ids=${ids.join(",")}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteCarCharges(
  carId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    deleteCharges(carId, ids),
  );

  return mutateAsync;
}
