import { BlockStack, DropZone, Text } from "@shopify/polaris";
import React from "react";
import { DropZoneFileType } from "@shopify/polaris/build/ts/src/components/DropZone";

interface FileUploadProps {
  label: string;
  file: File | null;
  setFile: (file: File) => void;
  type?: DropZoneFileType;
  accept?: string;
}

export default function FileUpload({
  label,
  file,
  setFile,
  type = "file",
  accept,
}: FileUploadProps) {
  return (
    <DropZone
      allowMultiple={false}
      onDrop={(_, acceptedFiles: File[]) => setFile(acceptedFiles[0])}
      type={type}
      accept={accept}
    >
      {file && (
        <div className="Polaris-BlockStack-Filename">
          <BlockStack align="center" inlineAlign="center" gap="500">
            <Text as="span">{file.name}</Text>
          </BlockStack>
        </div>
      )}
      {!file && <DropZone.FileUpload actionTitle={label} />}
    </DropZone>
  );
}
