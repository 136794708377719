import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import { AgentPayment, Page } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function getAgentPayments(
  agentId: string,
  page = 1,
  size = 10,
): Promise<Page<AgentPayment>> {
  const { data } = await axiosInstance.get(
    `/agents/${agentId}/payments?page=${page}&size=${size}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface Props {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  payments: AgentPayment[];
}

export function useAgentPayments(agentId: string, size = 10): Props {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.agents, agentId, "payments", page, size],
    () => getAgentPayments(agentId, page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    payments: data?.items || [],
  };
}
