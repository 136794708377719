import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../../types/common.types";
import TextFieldInput from "../../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import ImageFileUpload from "../../../../componenets/ImageFileUpload";
import { useUpdateCustomer } from "../hooks/useUpdateCustomer";

interface Props {
  customer: Customer;
}

const schema = yup
  .object({
    driver_license_number: yup.string().required(),
  })
  .required();

export default function DriverLicenseDetails({ customer }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [DriverLicenseFrontFaceFile, setDriverLicenseFrontFaceFile] =
    useState<File | null>(null);
  const [DriverLicenseBackFaceFile, setDriverLicenseBackFaceFile] =
    useState<File | null>(null);

  const { control, handleSubmit } = useForm<Customer>({
    defaultValues: customer,
    resolver: yupResolver(schema),
  });

  const updateCustomer = useUpdateCustomer();

  const onHandleSubmit = async (customerDetails: Customer) => {
    setLoading(true);

    try {
      await updateCustomer({
        CINFrontFaceFile: null,
        CINBackFaceFile: null,
        DriverLicenseFrontFaceFile,
        DriverLicenseBackFaceFile,
        customer: {
          ...customer,
          driver_license_number: customerDetails.driver_license_number,
        },
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.customers,
        customer.id,
      ]);

      onShowToast("Driver License Details Updated", false);

      setDriverLicenseFrontFaceFile(null);
      setDriverLicenseBackFaceFile(null);
      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("driver_license")}
          </Text>
          <Button
            variant="plain"
            onClick={() =>
              setEdit((prevState: boolean) => {
                if (!prevState) {
                  setDriverLicenseFrontFaceFile(null);
                  setDriverLicenseBackFaceFile(null);
                }

                return !prevState;
              })
            }
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <BlockStack gap="200">
            <p>
              {t("driver_license_number")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {customer.driver_license_number}
              </Text>
            </p>
            <InlineStack align="space-between" gap="100">
              <p className="w50">
                {t("front_face")}
                <br />
                <div className="afaLZ h200 p20">
                  <div className="qT8wK">
                    <img
                      className="llVfq"
                      src={customer.driver_license_card?.front_public_url}
                      alt="Driver's License Front"
                    />
                  </div>
                </div>
              </p>
              <p className="w50">
                {t("back_face")}
                <br />
                <div className="afaLZ h200 p20">
                  <div className="qT8wK">
                    <img
                      className="llVfq"
                      src={
                        customer.driver_license_card?.back_public_url ||
                        "https://placehold.co/600x400/FFF/000000/?text=No%20Card"
                      }
                      alt="Driver's License Back"
                    />
                  </div>
                </div>
              </p>
            </InlineStack>
          </BlockStack>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput
                control={control}
                name="driver_license_number"
                label="Driver License Number"
              />
              <FormLayout.Group>
                <ImageFileUpload
                  label={t("front_face")}
                  file={DriverLicenseFrontFaceFile}
                  setFile={setDriverLicenseFrontFaceFile}
                />
                <ImageFileUpload
                  label={t("back_face")}
                  file={DriverLicenseBackFaceFile}
                  setFile={setDriverLicenseBackFaceFile}
                />
              </FormLayout.Group>
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
