import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { InsurancePayment } from "../../../../types/common.types";

async function addInsurancePayment(
  payment: InsurancePayment,
): Promise<InsurancePayment> {
  return axiosInstance.post(`/insurance/payments`, payment, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

async function editInsurancePayment(
  payment: InsurancePayment,
): Promise<InsurancePayment> {
  return axiosInstance.put(`/insurance/payments/${payment.id}`, payment, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddOrEditInsurancePayment(
  edit: boolean,
): UseMutateAsyncFunction<
  InsurancePayment,
  unknown,
  InsurancePayment,
  unknown
> {
  const { mutateAsync } = useMutation((payment: InsurancePayment) =>
    edit ? editInsurancePayment(payment) : addInsurancePayment(payment),
  );

  return mutateAsync;
}
