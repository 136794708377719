import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { RecurringCharge } from "../../../../types/common.types";

async function addRecurringCharge(
  recurringCharge: RecurringCharge,
): Promise<RecurringCharge> {
  return axiosInstance.post(`/recurring_charges/`, recurringCharge, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddRecurringCharge(): UseMutateAsyncFunction<
  RecurringCharge,
  unknown,
  RecurringCharge,
  unknown
> {
  const { mutateAsync } = useMutation((recurringCharge: RecurringCharge) =>
    addRecurringCharge(recurringCharge),
  );

  return mutateAsync;
}
