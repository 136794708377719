import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const app = initializeApp({
  apiKey: "AIzaSyDSlSnJ5Ibd4rsFhgfKQosiUW1_sd60weo",
  authDomain: "vancar-39153.firebaseapp.com",
  databaseURL: "https://vancar-39153-default-rtdb.firebaseio.com",
  projectId: "vancar-39153",
  storageBucket: "vancar-39153.appspot.com",
  messagingSenderId: "731720743185",
  appId: "1:731720743185:web:e75ce271b6c8c7a90234b0",
  measurementId: "G-QSCRTM0NTX",
});
export const auth = getAuth(app);
