import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { Car } from "../../../types/common.types";
import { useAddCar } from "./hooks/useAddCar";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import FileUpload from "../../../componenets/FileUpload";

interface CarModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
    license_plate: yup.string().required(),
    total_cost: yup.number().required(),
    monthly_payment: yup.number().required(),
  })
  .required();

export default function NewCarModal({ active, setActive }: CarModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [carImage, setCarImage] = useState<File | null>(null);

  const { control, handleSubmit, reset } = useForm<Car>({
    defaultValues: {
      monthly_payment: 0,
    },
    resolver: yupResolver(schema),
  });
  const addCar = useAddCar();

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (car: Car) => {
    if (!carImage) {
      onShowToast("Please provide a car image.", true);
    }

    setLoading(true);

    try {
      await addCar({
        carImage: carImage as File,
        car,
      });

      await queryClient.invalidateQueries([queryKeysConstants.cars]);

      handleClose();

      onShowToast("Car Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_car")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextFieldInput
              control={control}
              label={t("car_name")}
              name="name"
              type="text"
            />
            <TextFieldInput
              control={control}
              label={t("car_license_plate")}
              name="license_plate"
              type="text"
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextFieldInput
              control={control}
              name="monthly_payment"
              label={t("monthly_payment")}
              type="number"
            />
            <TextFieldInput
              control={control}
              name="total_cost"
              label={t("total_cost")}
              type="number"
            />
          </FormLayout.Group>
          <FileUpload
            label={t("car_image")}
            file={carImage}
            setFile={setCarImage}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
