import {
  Card,
  IndexFilters,
  IndexTable,
  InlineStack,
  Page,
  Pagination,
  TabProps,
  useIndexResourceState,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useCallback, useContext, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { DeleteIcon } from "@shopify/polaris-icons";
import { Charge } from "../../../types/common.types";
import { useCharges } from "./hooks/useCharges";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import NewChargeModal from "./NewChargeModal";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useDeleteCharges } from "./hooks/useDeleteCharges";

export default function ChargesPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [chargeModalActive, setChargeModalActive] = useState(false);
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("active");
  const [queryValue, setQueryValue] = useState("");
  const [query] = useDebounce([queryValue], 1000);

  const { minPage, maxPage, isFetching, page, setPage, charges } = useCharges(
    10,
    status,
    query,
  );
  const deleteCharges = useDeleteCharges();

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(charges.map((charge: Charge) => ({ ...charge })));

  const tabs: TabProps[] = [
    {
      id: "this_month",
      content: t("this_month"),
      isLocked: true,
    },
    {
      id: "last_month",
      content: t("last_month"),
      isLocked: true,
    },
    {
      id: "all",
      content: t("all"),
      isLocked: true,
    },
  ];
  const columns = [
    { label: t("label") },
    { label: t("amount") },
    { label: t("date") },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const handleDeleteCharges = async () => {
    onShowToast("Deleting Charges", false);

    try {
      await deleteCharges(selectedResources);
      await queryClient.invalidateQueries([queryKeysConstants.charges]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = charges.map((charge: Charge, index) => (
    <IndexTable.Row
      id={charge.id as string}
      key={charge.id}
      position={index}
      selected={selectedResources.includes(charge.id as string)}
    >
      <IndexTable.Cell>{charge.label}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(charge.amount)}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(charge.date, true, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("charges")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setChargeModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          mode={mode}
          setMode={setMode}
          filters={[]}
          queryValue={queryValue}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          onClearAll={() => {}}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          cancelAction={{
            onAction: () => setQueryValue(""),
          }}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("charge"),
            plural: t("charges"),
          }}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={charges.length}
          loading={isFetching}
          bulkActions={[
            {
              icon: DeleteIcon,
              destructive: true,
              content: t("delete_charges"),
              onAction: handleDeleteCharges,
            },
          ]}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page !== minPage}
              hasNext={page !== maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
      <NewChargeModal
        active={chargeModalActive}
        setActive={setChargeModalActive}
      />
    </Page>
  );
}
