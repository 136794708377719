import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import SelectInput from "../../../componenets/SelectInput";
import { RecurringCharge } from "../../../types/common.types";
import { useAddRecurringCharge } from "./hooks/useAddRecurringCharge";

interface Props {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup.object().shape({
  label: yup.string().required(),
  amount: yup.number().min(0).required(),
  cycle: yup.string().required(),
  at_month: yup.number().when("cycle", {
    is: "EVERY_YEAR",
    then: yup.number().min(1).max(12).required(),
  }),
  at_day: yup.number().when("cycle", {
    is: "EVERY_YEAR",
    then: yup.number().min(1).max(31).required(),
  }),
});

export default function NewRecurringChargeModal({ active, setActive }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset, watch } = useForm<RecurringCharge>({
    defaultValues: {
      label: "",
      amount: 0,
      cycle: "EVERY_MONTH",
      at_month: 1,
      at_day: 1,
    },
    resolver: yupResolver(schema),
  });
  const watchCycle = watch("cycle");
  const addRecurringCharge = useAddRecurringCharge();

  const handleClose = () => {
    reset({
      label: "",
      amount: 0,
      cycle: "EVERY_MONTH",
      at_month: 1,
      at_day: 1,
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (charge: RecurringCharge) => {
    setLoading(true);

    try {
      const newCharge = await addRecurringCharge(charge);

      console.log(newCharge);

      await queryClient.invalidateQueries([
        queryKeysConstants.recurring_charges,
      ]);

      handleClose();

      onShowToast(t("charge_added"), false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_recurring_charge")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="label" label={t("label")} />
          <TextFieldInput
            control={control}
            label={t("amount")}
            name="amount"
            type="number"
          />
          <SelectInput
            control={control}
            name="cycle"
            label={t("cycle")}
            options={[
              {
                label: t("every_month"),
                value: "EVERY_MONTH",
              },
              {
                label: t("every_3_months"),
                value: "EVERY_3_MONTHS",
              },
              {
                label: t("every_year"),
                value: "EVERY_YEAR",
              },
            ]}
          />
          {watchCycle === "EVERY_YEAR" && (
            <FormLayout.Group>
              <TextFieldInput
                control={control}
                name="at_month"
                type="number"
                label={t("at_month")}
                min={1}
                max={12}
              />
              <TextFieldInput
                control={control}
                name="at_day"
                type="number"
                label={t("at_day")}
                max={31}
                min={1}
              />
            </FormLayout.Group>
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
