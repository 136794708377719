import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";
import {
  Page,
  RecurringChargePayment,
} from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function getRecurringChargePayments(
  recurringChargeId: string,
  page = 1,
  size = 10,
): Promise<Page<RecurringChargePayment>> {
  const { data } = await axiosInstance.get(
    `/recurring_charges/${recurringChargeId}/payments?page=${page}&size=${size}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface useCarsProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  payments: RecurringChargePayment[];
}

export function useRecurringChargePayments(
  recurringChargeId: string,
  size = 10,
): useCarsProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [
      queryKeysConstants.recurring_charges,
      recurringChargeId,
      "payments",
      page,
      size,
    ],
    () => getRecurringChargePayments(recurringChargeId, page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    payments: data?.items || [],
  };
}
