import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { RecurringChargePayment } from "../../../../../types/common.types";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import { queryClient } from "../../../../../services/queryClient.service";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import { useAddRecurringChargePayment } from "./hooks/useAddRecurringChargePayment";
import FileUpload from "../../../../../componenets/FileUpload";

interface Props {
  active: boolean;
  setActive: (status: boolean) => void;
  recurringChargeId: string;
}

const schema = yup
  .object({
    amount: yup.number().min(1).required(),
    date: yup.date().required(),
  })
  .required();

export default function NewRecurringChargePaymentModal({
  active,
  setActive,
  recurringChargeId,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState<File | null>(null);

  const { control, handleSubmit, reset } = useForm<RecurringChargePayment>({
    defaultValues: {
      amount: 0,
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const addPayment = useAddRecurringChargePayment(recurringChargeId);

  const handleClose = () => {
    reset({
      amount: 1,
      date: new Date(),
    });
    setInvoiceFile(null);

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (payment: RecurringChargePayment) => {
    setLoading(true);

    try {
      await addPayment({
        payment,
        invoiceFile,
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.recurring_charges,
        recurringChargeId,
      ]);

      handleClose();

      onShowToast("Payment Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_payment")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label={t("amount")}
            name="amount"
            type="number"
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
          <FileUpload
            label={t("invoice")}
            file={invoiceFile}
            setFile={setInvoiceFile}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
