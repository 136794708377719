import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Reservation } from "../../../../types/common.types";

async function addReservation(reservation: Reservation): Promise<Reservation> {
  const { data } = await axiosInstance.post(`/reservations/`, reservation, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

async function editReservation(
  reservationId: string,
  reservation: Reservation,
): Promise<Reservation> {
  const { data } = await axiosInstance.put(
    `/reservations/${reservationId}`,
    reservation,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  return data;
}

export function useAddOrEditReservation(
  reservationId: string | null,
): UseMutateAsyncFunction<Reservation, unknown, Reservation, unknown> {
  const { mutateAsync } = useMutation((reservation: Reservation) =>
    reservationId
      ? editReservation(reservationId, reservation)
      : addReservation(reservation),
  );

  return mutateAsync;
}
