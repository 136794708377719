import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { MonthlyPayment } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addMonthlyPayment(
  monthlyPayment: MonthlyPayment,
): Promise<MonthlyPayment> {
  return axiosInstance.post(
    `/cars/${monthlyPayment.car_id}/monthly_payments/`,
    monthlyPayment,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}
export function useAddMonthlyPayment(): UseMutateAsyncFunction<
  MonthlyPayment,
  unknown,
  MonthlyPayment,
  unknown
> {
  const { mutateAsync } = useMutation((monthlyPayment: MonthlyPayment) =>
    addMonthlyPayment(monthlyPayment),
  );

  return mutateAsync;
}
