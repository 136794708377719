import { Modal, FormLayout, TextField } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { Car, Reservation } from "../../../types/common.types";
import { useCars } from "../Cars/hooks/useCars";
import SelectInput from "../../../componenets/SelectInput";
import { useAddOrEditReservation } from "./hooks/useAddOrEditReservation";
import { addDays, formatDate } from "../../../helpers/helpers";
import DateTimePickerInput from "../../../componenets/DateTimePickerInput";
import { useDownloadReservationAttestation } from "./hooks/useDownloadReservationAttestation";

interface Props {
  reservation: Reservation | null;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    car_id: yup.string().required(),
    customer_name: yup.string().required(),
    customer_cin: yup.string().required(),
    phone_number: yup.string().required(),
    from_date: yup.date().required(),
    to_date: yup.date().required(),
    number_of_days: yup.number().min(1).required(),
    status: yup.string(),
  })
  .required();

export default function ReservationModal({
  reservation,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [attestationLoading, setAttestationLoading] = useState(false);

  const statuses = ["PENDING", "CONFIRMED", "CANCELLED"];

  const { isLoading: isCarsLoading, cars } = useCars(100);

  const { control, handleSubmit, reset, setValue, watch } =
    useForm<Reservation>({
      defaultValues: {
        car_id: reservation?.car_id || "",
        customer_name: reservation?.customer_name || "",
        customer_cin: reservation?.customer_cin || "",
        phone_number: reservation?.phone_number || "",
        from_date: reservation?.from_date || new Date(),
        number_of_days: reservation?.number_of_days || 1,
        price_per_day: reservation?.price_per_day || 0,
        to_date: reservation?.to_date || addDays(new Date(), 1),
        deposit: reservation?.deposit || 0,
        status: reservation?.status || "PENDING",
      },
      resolver: yupResolver(schema),
    });
  const watchFromDate = watch("from_date");
  const watchToDate = watch("to_date");
  const watchNumberOfDays = watch("number_of_days");

  const addOrEditReservation = useAddOrEditReservation(reservation?.id || null);
  const downloadAttestation = useDownloadReservationAttestation(
    reservation?.id as string,
  );

  const handleClose = () => {
    reset({
      customer_name: "",
      customer_cin: "",
      phone_number: "",
      from_date: new Date(),
      to_date: addDays(new Date(), 1),
      number_of_days: 1,
      price_per_day: 0,
      status: "PENDING",
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (reservation: Reservation) => {
    setLoading(true);

    try {
      await addOrEditReservation(reservation);

      await queryClient.invalidateQueries([queryKeysConstants.reservations]);

      handleClose();

      onShowToast("Reservation Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  const onHandleDownloadAttestation = async () => {
    setAttestationLoading(true);

    const res = await downloadAttestation();

    const fileType = res.headers["content-type"];
    const blob = new Blob([res.data], {
      type: fileType,
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    window.URL.revokeObjectURL(url);

    setAttestationLoading(false);
  };

  const secondaryActions = [];

  if (reservation && reservation.status === "CONFIRMED") {
    secondaryActions.push({
      content: t("generate_attestation"),
      onAction: onHandleDownloadAttestation,
      loading: attestationLoading,
    });
  }

  secondaryActions.push({
    content: t("cancel"),
    onAction: handleClose,
  });

  useEffect(() => {
    if (cars.length) {
      setValue("car_id", reservation?.car_id || (cars[0].id as string));
    }
  }, [cars]);

  useEffect(() => {
    setValue(
      "to_date",
      addDays(watchFromDate, parseInt(watchNumberOfDays.toString() || "1", 10)),
    );
  }, [watchFromDate, watchNumberOfDays]);

  useEffect(() => {
    reset({
      car_id: reservation?.car_id || "",
      customer_name: reservation?.customer_name || "",
      customer_cin: reservation?.customer_cin || "",
      phone_number: reservation?.phone_number || "",
      from_date: reservation?.from_date || new Date(),
      to_date: reservation?.to_date || addDays(new Date(), 1),
      number_of_days: reservation?.number_of_days || 1,
      price_per_day: reservation?.price_per_day || 0,
      deposit: reservation?.deposit || 0,
      status: reservation?.status || "PENDING",
    });
  }, [reservation]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t(reservation ? "edit_reservation" : "new_reservation")}
      primaryAction={{
        content: t("save"),
        disabled: loading || isCarsLoading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <FormLayout>
          <SelectInput
            control={control}
            name="car_id"
            label={t("car")}
            options={cars.map((car: Car) => ({
              label: `${car.name} (${car.license_plate})`,
              value: car.id,
            }))}
          />
          <TextFieldInput
            control={control}
            label={t("customer_name")}
            name="customer_name"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("cin")}
            name="customer_cin"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("phone")}
            name="phone_number"
          />
          <DateTimePickerInput
            control={control}
            name="from_date"
            dateLabel={t("from_date")}
            timeLabel={t("from_time")}
            initialDate={
              reservation?.from_date
                ? new Date(reservation?.from_date.toString())
                : new Date()
            }
          />
          <TextFieldInput
            control={control}
            name="number_of_days"
            label={t("number_of_days")}
            type="number"
          />
          <TextFieldInput
            control={control}
            name="price_per_day"
            label={t("price_per_day")}
            type="number"
          />
          <TextField
            label={t("to_date")}
            autoComplete=""
            value={formatDate(watchToDate, false, true)}
            disabled
          />
          <TextFieldInput
            control={control}
            name="deposit"
            label={t("deposit")}
            type="number"
          />
          {reservation && (
            <SelectInput
              control={control}
              name="status"
              label={t("status")}
              options={statuses.map((status) => ({
                label: t(status.toLowerCase()),
                value: status,
              }))}
            />
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
