import { BlockStack, DropZone, Text, Thumbnail } from "@shopify/polaris";
import React from "react";

interface FileUploadProps {
  label: string;
  file: File | null;
  setFile: (file: File) => void;
}

export default function ImageFileUpload({
  label,
  file,
  setFile,
}: FileUploadProps) {
  return (
    <DropZone
      allowMultiple={false}
      onDrop={(_, acceptedFiles: File[]) => setFile(acceptedFiles[0])}
      type="image"
      accept="image/*"
    >
      {file && (
        <div className="Polaris-BlockStack-Filename">
          <BlockStack align="center" inlineAlign="center" gap="200">
            <Thumbnail
              size="large"
              alt={file.name}
              source={window.URL.createObjectURL(file)}
            />
            <Text as="span">{file.name}</Text>
          </BlockStack>
        </div>
      )}
      {!file && <DropZone.FileUpload actionTitle={label} />}
    </DropZone>
  );
}
