import {
  IndexTable,
  Page,
  useSetIndexFiltersMode,
  IndexFilters,
  InlineStack,
  Pagination,
  Card,
  ChoiceList,
  IndexFiltersProps,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import { BlacklistedProfile } from "../../../types/common.types";
import { useBlacklist } from "./hooks/useBlacklist";
import { useBlacklistFilters } from "./hooks/useBlacklistFilters";
import { isEmpty } from "../../../helpers/helpers";

export default function BlacklistPage() {
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [selected] = useState(0);
  const [queryValue, setQueryValue] = useState("");

  const [cities, setCities] = useState<string[] | undefined>(undefined);

  const { minPage, maxPage, isFetching, page, setPage, profiles } =
    useBlacklist(15, queryValue, cities);
  const { isFetching: isFiltersFetching, filters: blacklistFilters } =
    useBlacklistFilters();

  const handleCitiesChange = useCallback(
    (value: string[]) => setCities(value),
    [],
  );
  const handleCitiesRemove = useCallback(() => setCities(undefined), []);

  function disambiguateLabel(key: string, value: string | any[]): string {
    switch (key) {
      case "city":
        return (value as string[]).map((val) => val).join(", ");
      default:
        return value as string;
    }
  }

  const columns = [
    { label: t("name") },
    { label: t("cin") },
    { label: t("driver_license_number") },
    { label: t("city") },
    { label: t("region") },
    { label: t("reason") },
  ];

  const filters = [
    {
      key: "city",
      label: t("city"),
      filter: (
        <ChoiceList
          title={t("city")}
          titleHidden
          choices={
            blacklistFilters?.cities.map((city) => ({
              label: city,
              value: city,
            })) || []
          }
          selected={cities || []}
          onChange={handleCitiesChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleCitiesRemove();
  }, [handleCitiesRemove]);

  const rowMarkup = profiles.map((profile: BlacklistedProfile, index) => (
    <IndexTable.Row id={profile.id as string} key={profile.id} position={index}>
      <IndexTable.Cell>{profile.name || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{profile.cin || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{profile.driver_license_number || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{profile.city || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{profile.region || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{profile.reason || "-"}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  const appliedFilters: IndexFiltersProps["appliedFilters"] = [];

  if (cities && !isEmpty(cities)) {
    const key = "city";

    appliedFilters.push({
      key,
      label: disambiguateLabel(key, cities),
      onRemove: handleCitiesRemove,
    });
  }

  return (
    <Page fullWidth title={t("blacklist")}>
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder={t("searching")}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => {}}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={[]}
          selected={selected}
          onSelect={() => {}}
          filters={filters}
          onClearAll={handleFiltersClearAll}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
          appliedFilters={appliedFilters}
        />
        <IndexTable
          resourceName={{
            singular: t("profile"),
            plural: t("profiles"),
          }}
          itemCount={profiles.length}
          loading={isFetching || isFiltersFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
    </Page>
  );
}
