import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Customer } from "../../../../types/common.types";

async function updateCustomer(
  customer: Customer,
  CINFrontFaceFile: File | null,
  CINBackFaceFile: File | null,
  DriverLicenseFrontFaceFile: File | null,
  DriverLicenseBackFaceFile: File | null,
): Promise<Customer> {
  const form = new FormData();

  if (CINFrontFaceFile) {
    form.append("cin_front_face_file", CINFrontFaceFile);
  }

  if (CINBackFaceFile) {
    form.append("cin_back_face_file", CINBackFaceFile);
  }

  if (DriverLicenseFrontFaceFile) {
    form.append("driver_license_front_face_file", DriverLicenseFrontFaceFile);
  }

  if (DriverLicenseBackFaceFile) {
    form.append("driver_license_back_face_file", DriverLicenseBackFaceFile);
  }

  form.append("padding", "not-needed");

  return axiosInstance.put(`/customers/${customer.id}`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: customer,
  });
}

interface Props {
  customer: Customer;
  CINFrontFaceFile: File | null;
  CINBackFaceFile: File | null;
  DriverLicenseFrontFaceFile: File | null;
  DriverLicenseBackFaceFile: File | null;
}

export function useUpdateCustomer(): UseMutateAsyncFunction<
  Customer,
  unknown,
  Props,
  unknown
> {
  const { mutateAsync } = useMutation(
    ({
      customer,
      CINFrontFaceFile,
      CINBackFaceFile,
      DriverLicenseFrontFaceFile,
      DriverLicenseBackFaceFile,
    }: Props) =>
      updateCustomer(
        customer,
        CINFrontFaceFile,
        CINBackFaceFile,
        DriverLicenseFrontFaceFile,
        DriverLicenseBackFaceFile,
      ),
  );

  return mutateAsync;
}
