import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Button,
  FormLayout,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../../types/common.types";
import TextFieldInput from "../../../../componenets/TextFieldInput";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateCustomer } from "../hooks/useUpdateCustomer";

interface CustomerDetailsProps {
  customer: Customer;
}

const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().nullable(),
    email: yup.string().nullable(),
    phone: yup.string().required(),
  })
  .required();

export default function CustomerDetails({ customer }: CustomerDetailsProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Customer>({
    defaultValues: customer,
    resolver: yupResolver(schema),
  });

  const updateCustomer = useUpdateCustomer();

  const onHandleSubmit = async (customerDetails: Customer) => {
    setLoading(true);

    try {
      await updateCustomer({
        CINFrontFaceFile: null,
        CINBackFaceFile: null,
        DriverLicenseFrontFaceFile: null,
        DriverLicenseBackFaceFile: null,
        customer: {
          ...customer,
          name: customerDetails.name,
          address: customerDetails.address,
          phone: customerDetails.phone,
          email: customerDetails.email,
        },
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.customers,
        customer.id,
      ]);

      onShowToast("Customer Details Updated", false);
      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("customer_details")}
          </Text>
          <Button
            variant="plain"
            onClick={() => setEdit((prevState: boolean) => !prevState)}
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <BlockStack gap="200">
            <p>
              {t("name")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {customer.name}
              </Text>
            </p>
            <p>
              {t("address")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {customer.address}
              </Text>
            </p>
            <p>
              {t("phone")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {customer.phone}
              </Text>
            </p>
          </BlockStack>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput control={control} name="name" label={t("name")} />
              <TextFieldInput
                control={control}
                name="address"
                label={t("address")}
              />
              <TextFieldInput
                control={control}
                name="phone"
                label={t("phone")}
              />
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
