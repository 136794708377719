import { Controller } from "react-hook-form";
import { Select } from "@shopify/polaris";
import React from "react";

interface Props {
  control: any;
  name: string;
  label: string;
  options: any;
  disabled?: boolean | undefined;
}

export default function SelectInput({
  control,
  name,
  label,
  options,
  disabled,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <Select
          disabled={disabled}
          error={errors[name]?.message as any}
          label={label}
          options={options}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
}
