import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { RentPayment } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useAddRentPayment } from "./hooks/useAddRentPayment";

interface Props {
  rentId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    date: yup.date().required(),
    amount: yup.number().required(),
  })
  .required();

export default function AddRentPaymentModal({
  rentId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm<RentPayment>({
    defaultValues: {
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const addRentPayment = useAddRentPayment();

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (payment: RentPayment) => {
    setLoading(true);

    try {
      await addRentPayment({
        ...payment,
        rent_id: rentId,
      });

      await queryClient.invalidateQueries([queryKeysConstants.rents, rentId]);

      reset({
        date: new Date(),
        amount: 0,
      });
      handleClose();

      onShowToast("Monthly Payment Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      title={t("new_payment")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction() {
            handleClose();
          },
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <DatePickerInput control={control} name="date" label={t("date")} />
          <TextFieldInput control={control} name="amount" label={t("amount")} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
