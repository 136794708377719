import { BlockStack, Card, Text } from "@shopify/polaris";
import { BarChart } from "@shopify/polaris-viz";
import { ChartState } from "@shopify/polaris-viz-core/build/ts/types";
import { MonthlyStats } from "../../../../types/common.types";
import { formatMoney } from "../../../../helpers/helpers";

export default function RevenuePerCarChart({
  stats,
  isLoading,
}: {
  stats: MonthlyStats;
  isLoading: boolean;
}) {
  const name = "Revenue per car in current month";

  return (
    <Card>
      <BlockStack gap="800">
        <Text as="h2" variant="bodySm">
          {name}
        </Text>
        <div style={{ height: "20rem" }}>
          <BarChart
            showLegend={false}
            data={[
              {
                name,
                data: stats?.revenue_in_current_month_by_car || [],
              },
            ]}
            theme="Light"
            yAxisOptions={{
              labelFormatter: (value) => formatMoney(value as number),
            }}
            tooltipOptions={{
              valueFormatter: (value) => formatMoney(value as number),
            }}
            state={
              isLoading ? ("Loading" as ChartState) : ("Success" as ChartState)
            }
          />
        </div>
      </BlockStack>
    </Card>
  );
}
