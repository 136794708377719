import {
  Page,
  Spinner,
  BlockStack,
  InlineGrid,
  Popover,
  OptionList,
  Button,
  Text,
  InlineStack,
} from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMonthlyStats } from "./hooks/useMonthlyStats";
import ChargesSplitChart from "./components/ChargesSplitChart";
import { MonthlyStats } from "../../../types/common.types";
import ChargesRevenueChart from "./components/ChargesRevenueChart";
import RevenuePerCarChart from "./components/RevenuePerCarChart";
import { formatDate } from "../../../helpers/helpers";
import NumberOfRentedDaysPerCarChart from "./components/NumberOfRentedDaysPerCarChart";

export default function MonthlyStatsPage() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>(["CURRENT_MONTH"]);
  const [popoverActive, setPopoverActive] = useState(false);

  const { monthlyStats, isLoading, isFetching } = useMonthlyStats(selected[0]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {t(selected[0].toLowerCase())}
    </Button>
  );

  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <PolarisVizProvider>
      <Page fullWidth>
        <BlockStack gap="500">
          <InlineStack align="space-between">
            <Popover
              active={popoverActive}
              activator={activator}
              onClose={togglePopoverActive}
              preferredAlignment="left"
            >
              <OptionList
                allowMultiple={false}
                onChange={(v) => {
                  setSelected(v);
                  togglePopoverActive();
                }}
                options={[
                  {
                    value: "CURRENT_MONTH",
                    label: t("current_month"),
                  },
                  {
                    value: "LAST_MONTH",
                    label: t("last_month"),
                  },
                ]}
                selected={selected}
              />
            </Popover>
            <Text as="span">
              {t("last_updated_at")} :{" "}
              {formatDate(monthlyStats?.last_update_at, false, false, true)}
            </Text>
          </InlineStack>
          <InlineGrid columns={{ sm: 1, md: 2, xl: 3 }} gap="200">
            <ChargesRevenueChart
              stats={monthlyStats as MonthlyStats}
              isLoading={isFetching}
            />
            <ChargesSplitChart
              stats={monthlyStats as MonthlyStats}
              isLoading={isFetching}
            />
            <RevenuePerCarChart
              stats={monthlyStats as MonthlyStats}
              isLoading={isFetching}
            />
            <NumberOfRentedDaysPerCarChart
              stats={monthlyStats as MonthlyStats}
              isLoading={isFetching}
            />
          </InlineGrid>
        </BlockStack>
      </Page>
    </PolarisVizProvider>
  );
}
