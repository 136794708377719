import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteCharges(ids: string[]): Promise<void> {
  await axiosInstance.delete(`/charges/?ids=${ids.join(",")}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteCharges(): UseMutateAsyncFunction<
  void,
  unknown,
  string[],
  unknown
> {
  const { mutateAsync } = useMutation((ids: string[]) => deleteCharges(ids));

  return mutateAsync;
}
