import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { BlacklistFilters } from "../../../../types/common.types";

async function getBlacklistFilters(): Promise<BlacklistFilters> {
  const { data } = await axiosInstance.get(`/blacklist/filters`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  filters: BlacklistFilters | null;
}

export function useBlacklistFilters(): Props {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.blacklist, "filters"],
    () => getBlacklistFilters(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isFetching,
    isLoading,
    filters: data || null,
  };
}
