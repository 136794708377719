import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { OverAllStats } from "../../../../types/common.types";

async function getStats(): Promise<OverAllStats> {
  const { data } = await axiosInstance.get(`/stats/overall`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface Props {
  isLoading: boolean;
  isFetching: boolean;
  overAllStats: OverAllStats | null;
}

export function useOverAllStats(): Props {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.stats, "overall"],
    () => getStats(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    overAllStats: data || null,
  };
}
