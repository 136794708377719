import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Page, RecurringCharge } from "../../../../types/common.types";

async function getRecurringCharges(
  page = 1,
  size = 10,
  query = "",
): Promise<Page<RecurringCharge>> {
  const { data } = await axiosInstance.get(
    `/recurring_charges/?page=${page}&size=${size}&query=${query}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface useProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  recurringCharges: RecurringCharge[];
}

export function useRecurringCharges(size = 10, query = ""): useProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.recurring_charges, page, query],
    () => getRecurringCharges(page, size, query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    recurringCharges: data?.items || [],
  };
}
