import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteCharges(
  recurringPaymentId: string,
  ids: string[],
): Promise<void> {
  await axiosInstance.delete(
    `/recurring_charges/${recurringPaymentId}/payments?ids=${ids.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeleteRecurringPaymentCharges(
  recurringPaymentId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    deleteCharges(recurringPaymentId, ids),
  );

  return mutateAsync;
}
