import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Charge, Page } from "../../../../types/common.types";

async function getCharges(
  page = 1,
  size = 10,
  status = "",
  query = "",
): Promise<Page<Charge>> {
  const { data } = await axiosInstance.get(
    `/charges/?page=${page}&size=${size}&status=${status}&query=${query}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface useProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  charges: Charge[];
}

export function useCharges(size = 10, status = "", query = ""): useProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.charges, page, status, query],
    () => getCharges(page, size, status, query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    charges: data?.items || [],
  };
}
