import { useQuery } from "@tanstack/react-query";
import { RecurringCharge } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getRecurringCharge(
  recurringChargeId: string,
): Promise<RecurringCharge> {
  const { data } = await axiosInstance.get(
    `/recurring_charges/${recurringChargeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
  return data;
}

interface Props {
  isLoading: boolean;
  isError: boolean;
  recurringCharge: RecurringCharge | undefined;
}

export function useRecurringCharge(customerId: string): Props {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.recurring_charges, customerId],
    () => getRecurringCharge(customerId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    recurringCharge: data,
  };
}
