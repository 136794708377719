import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Rent } from "../../../../types/common.types";

async function addRent(contractFile: File, rent: Rent): Promise<Rent> {
  const formData = new FormData();

  formData.append("contract_file", contractFile);

  const { data } = await axiosInstance.post(`/rents/`, formData, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: rent,
  });

  return data;
}

interface Props {
  contractFile: File;
  rent: Rent;
}

export function useAddRent(): UseMutateAsyncFunction<
  Rent,
  unknown,
  Props,
  unknown
> {
  const { mutateAsync } = useMutation(({ contractFile, rent }: Props) =>
    addRent(contractFile, rent),
  );

  return mutateAsync;
}
