import {
  BlockStack,
  Box,
  Button,
  Card,
  IndexTable,
  InlineGrid,
  InlineStack,
  Layout,
  Page,
  Pagination,
  Text,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInsurancePayments } from "./hooks/useInsurancePayments";
import { InsurancePayment } from "../../../types/common.types";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import InsurancePaymentModal from "./NewInsurancePaymentModal";
import { useInsuranceStats } from "./hooks/useInsuranceStats";

export default function InsurancePage() {
  const { t } = useTranslation();
  const [insurancePaymentModalActive, setInsurancePaymentModalActive] =
    useState(false);
  const [selectedPayment, setSelectedPayment] =
    useState<InsurancePayment | null>(null);

  const columns = [
    { label: t("date") },
    { label: t("amount") },
    { label: t("status") },
  ];

  const { minPage, maxPage, isFetching, page, setPage, payments } =
    useInsurancePayments(10);
  const { stats } = useInsuranceStats();

  const rowMarkup = payments.map((payment: InsurancePayment, index) => (
    <IndexTable.Row
      id={payment.id as string}
      key={payment.id}
      position={index}
      onClick={() => {
        setSelectedPayment(payment);
        setInsurancePaymentModalActive(true);
      }}
    >
      <IndexTable.Cell>{formatDate(payment.date, true, true)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
      <IndexTable.Cell>{t(payment.status.toLowerCase())}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page title={t("insurance")} fullWidth>
      <Layout>
        <Layout.Section>
          <Card>
            <InlineGrid columns={{ sm: 2, lg: 3 }}>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("amount_paid")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(stats.amount_paid)}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("amount_left")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(stats.amount_left)}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("total")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(stats.total || 0)}
                </Text>
              </BlockStack>
            </InlineGrid>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card padding="0">
            <BlockStack gap="200">
              <Box
                paddingInlineStart="300"
                paddingInlineEnd="300"
                paddingBlockStart="200"
              >
                <InlineStack align="space-between">
                  <Text as="span" fontWeight="bold">
                    {t("payments")}
                  </Text>
                  <Button
                    variant="plain"
                    onClick={() => setInsurancePaymentModalActive(true)}
                  >
                    {t("add")}
                  </Button>
                </InlineStack>
              </Box>

              <IndexTable
                resourceName={{
                  singular: t("payment"),
                  plural: t("payments"),
                }}
                itemCount={payments.length}
                loading={isFetching}
                headings={
                  columns.map(
                    (column): IndexTableHeading => ({ title: column.label }),
                  ) as NonEmptyArray<IndexTableHeading>
                }
                selectable={false}
              >
                {rowMarkup}
              </IndexTable>
              <div
                style={{
                  borderTop: ".0625rem solid var(--p-border-subdued)",
                  margin: "auto",
                  padding: "1.5rem 1rem",
                }}
              >
                <InlineStack align="center">
                  <Pagination
                    hasPrevious={page > minPage}
                    hasNext={page < maxPage}
                    onPrevious={() => setPage(page - 1)}
                    onNext={() => setPage(page + 1)}
                  />
                </InlineStack>
              </div>
            </BlockStack>
            <InsurancePaymentModal
              active={insurancePaymentModalActive}
              setActive={() => {
                setSelectedPayment(null);
                setInsurancePaymentModalActive(false);
              }}
              payment={selectedPayment}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
