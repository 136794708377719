import { useQuery } from "@tanstack/react-query";
import { Car } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getCar(carId: string): Promise<Car> {
  const { data } = await axiosInstance.get(`/cars/${carId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface useCarProps {
  isLoading: boolean;
  isError: boolean;
  car: Car | undefined;
}

export function useCar(carId: string): useCarProps {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.cars, carId],
    () => getCar(carId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    car: data,
  };
}
