import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CarDocument } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addCarDocument(
  carId: string,
  carDocument: CarDocument,
  documentFile: File,
): Promise<CarDocument> {
  const form = new FormData();

  form.append("file", documentFile);

  return axiosInstance.post(`/cars/${carId}/documents`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: carDocument,
  });
}

interface addCarDocumentProps {
  carDocument: CarDocument;
  documentFile: File;
}

export function useAddCarDocument(
  carId: string,
): UseMutateAsyncFunction<CarDocument, unknown, addCarDocumentProps, unknown> {
  const { mutateAsync } = useMutation(
    ({ carDocument, documentFile }: addCarDocumentProps) =>
      addCarDocument(carId, carDocument, documentFile),
  );

  return mutateAsync;
}
