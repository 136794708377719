import { useController } from "react-hook-form";
import React, { useState } from "react";
import { DateTimePicker } from "./DateTimePicker";
import { getTimeString } from "../helpers/helpers";

interface Props {
  control: any;
  name: string;
  timeLabel: string;
  dateLabel: string;
  initialDate?: Date | string;
}

export default function DateTimePickerInput({
  control,
  name,
  timeLabel,
  dateLabel,
  initialDate,
}: Props) {
  const { field } = useController({
    name,
    control,
  });

  const initialValue =
    typeof initialDate === "string" ? new Date(initialDate) : initialDate;

  const [selectedDates, setSelectedDates] = useState({
    start: initialValue || new Date(),
    end: initialValue || new Date(),
  });
  const [selectedTime, setSelectedTime] = useState(
    getTimeString(initialValue || new Date()),
  );

  return (
    <DateTimePicker
      selectedDates={selectedDates}
      setSelectedDates={setSelectedDates}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      timeLabel={timeLabel}
      dateLabel={dateLabel}
      onChange={(v: Date) => field.onChange(v)}
    />
  );
}
