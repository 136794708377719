import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomer } from "./hooks/useCustomer";
import CustomerDetails from "./components/CustomerDetails";
import { Customer } from "../../../types/common.types";
import CinDetails from "./components/CinDetails";
import DriverLicenseDetails from "./components/DriverLicenseDetails";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useDeleteCustomer } from "./hooks/useDeleteCustomer";
import { API_BASE_URL } from "../../../constants/api.constants";

export default function CustomerPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const { customer, isLoading, isError } = useCustomer(customerId as string);
  const deleteCustomer = useDeleteCustomer();

  const onHandleDelete = async () => {
    setDeleteLoading(true);

    try {
      await deleteCustomer(customerId as string);

      onShowToast("Customer Deleted.", false);

      navigate("/admin/customers");

      await queryClient.invalidateQueries([queryKeysConstants.customers]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setDeleteLoading(false);
  };

  const onPrintDriverLicense = () => {
    window
      .open(
        `${API_BASE_URL}/customers/${customerId}/pdf/driver_license`,
        "_blank",
      )
      ?.focus();
  };

  const onPrintCIN = () => {
    window
      .open(`${API_BASE_URL}/customers/${customerId}/pdf/cin`, "_blank")
      ?.focus();
  };

  if (isError) {
    navigate("/admin/customers");
  }

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      fullWidth
      title={customer?.name}
      backAction={{
        onAction: () => navigate("/admin/customers"),
      }}
      primaryAction={{
        content: t("delete"),
        destructive: true,
        onAction: onHandleDelete,
        loading: deleteLoading,
      }}
      actionGroups={[
        {
          title: t("more"),
          actions: [
            {
              content: t("print_driver_license"),
              onAction: onPrintDriverLicense,
            },
            {
              content: t("print_cin"),
              onAction: onPrintCIN,
            },
          ],
        },
      ]}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <CustomerDetails customer={customer as Customer} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <DriverLicenseDetails customer={customer as Customer} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CinDetails customer={customer as Customer} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
