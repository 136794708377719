import {
  Card,
  IndexTable,
  InlineStack,
  Link,
  Page,
  Pagination,
  Text,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAgents } from "./hooks/useAgents";
import { Agent } from "../../../types/common.types";
import { formatMoney } from "../../../helpers/helpers";
import NewAgentModal from "./NewAgentModal";

export default function AgentsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalActive, setModalActive] = useState(false);

  const columns = [
    { label: t("name") },
    { label: t("salary") },
    { label: t("insurance") },
    { label: t("type") },
  ];

  const { minPage, maxPage, isFetching, page, setPage, agents } = useAgents(10);

  const rowMarkup = agents.map((agent: Agent, index) => (
    <IndexTable.Row id={agent.id as string} key={agent.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          onClick={() => navigate(`/admin/agents/${agent.id}`)}
        >
          <Text fontWeight="bold" as="span">
            {agent.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(agent.salary)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(agent.insurance)}</IndexTable.Cell>
      <IndexTable.Cell>{t(agent.type.toLowerCase())}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("agents")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexTable
          resourceName={{
            singular: t("agent"),
            plural: t("agents"),
          }}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={agents.length}
          loading={isFetching}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page !== minPage}
              hasNext={page !== maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
      <NewAgentModal active={modalActive} setActive={setModalActive} />
    </Page>
  );
}
