import { BlockStack, Card, Text } from "@shopify/polaris";
import { DonutChart } from "@shopify/polaris-viz";
import React, { type ReactNode } from "react";
import { InnerValueContents } from "@shopify/polaris-viz/build/ts/types";
import { useTranslation } from "react-i18next";
import { ChartState } from "@shopify/polaris-viz-core/build/ts/types";
import { formatMoney } from "../../../../helpers/helpers";
import { MonthlyStats, StatsItem } from "../../../../types/common.types";

export default function ChargesSplitChart({
  stats,
  isLoading,
}: {
  stats: MonthlyStats;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const data = stats.charges_split_in_current_month.map(
    (statsCharge: StatsItem) => ({
      name: t(statsCharge.name),
      data: [
        {
          key: t(statsCharge.name),
          value: statsCharge.value,
        },
      ],
    }),
  );
  const handleRenderValueContent = (values: InnerValueContents): ReactNode => {
    const totalCharges = stats?.total_charges_in_current_month;

    if (values.activeIndex === -1) {
      return `${formatMoney(totalCharges)}`;
    }

    return formatMoney(values.activeValue || 0);
  };

  return (
    <Card>
      <BlockStack gap="800">
        <Text as="h2" variant="bodySm">
          Charges split in current month
        </Text>

        <div style={{ height: "20rem" }}>
          <DonutChart
            showLegend
            legendPosition="bottom"
            showLegendValues
            data={data}
            theme="Light"
            labelFormatter={(value) => formatMoney(value as number)}
            renderInnerValueContent={handleRenderValueContent}
            state={
              isLoading ? ("Loading" as ChartState) : ("Success" as ChartState)
            }
          />
        </div>
      </BlockStack>
    </Card>
  );
}
