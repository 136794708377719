import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Page, Reservation } from "../../../../types/common.types";

async function getReservations(
  page = 1,
  size = 10,
  query = "",
  status = "active",
): Promise<Page<Reservation>> {
  const { data } = await axiosInstance.get(`/reservations/`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      page,
      size,
      query,
      status,
    },
  });
  return data;
}

interface Props {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  reservations: Reservation[];
}

export function useReservations(
  size = 10,
  query = "",
  status = "active",
): Props {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.reservations, page, query, status],
    () => getReservations(page, size, query, status),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    reservations: data?.items || [],
  };
}
