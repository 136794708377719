import { BlockStack, Card, Text } from "@shopify/polaris";
import { BarChart } from "@shopify/polaris-viz";
import { ChartState } from "@shopify/polaris-viz-core/build/ts/types";
import { formatMoney } from "../../../../helpers/helpers";
import { OverAllStats } from "../../../../types/common.types";

export default function AverageMonthlyCostPerCarChart({
  stats,
  isLoading,
}: {
  stats: OverAllStats;
  isLoading: boolean;
}) {
  const name = "Average monthly cost per car";

  return (
    <Card>
      <BlockStack gap="800">
        <Text as="h2" variant="bodySm">
          {name}
        </Text>
        <div style={{ height: "20rem" }}>
          <BarChart
            showLegend={false}
            data={[
              {
                name,
                data: stats?.average_monthly_cost_by_car.map((stat) => ({
                  key: stat.name,
                  value: stat.value,
                })),
              },
            ]}
            theme="Light"
            yAxisOptions={{
              labelFormatter: (value) => formatMoney(value as number),
            }}
            tooltipOptions={{
              valueFormatter: (value) => formatMoney(value as number),
            }}
            state={
              isLoading ? ("Loading" as ChartState) : ("Success" as ChartState)
            }
          />
        </div>
      </BlockStack>
    </Card>
  );
}
