import { useQuery } from "@tanstack/react-query";
import { Rent } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getRent(rentId: string): Promise<Rent> {
  const { data } = await axiosInstance.get(`/rents/${rentId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface useCarProps {
  isLoading: boolean;
  isError: boolean;
  rent: Rent | undefined;
}

export function useRent(rentId: string): useCarProps {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.rents, rentId],
    () => getRent(rentId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    rent: data,
  };
}
