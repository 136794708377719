import {
  BlockStack,
  Button,
  Card,
  FormLayout,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../../types/common.types";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateCustomer } from "../hooks/useUpdateCustomer";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import TextFieldInput from "../../../../componenets/TextFieldInput";
import ImageFileUpload from "../../../../componenets/ImageFileUpload";

interface Props {
  customer: Customer;
}

const schema = yup
  .object({
    cin: yup.string().nullable(),
  })
  .required();

export default function CinDetails({ customer }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [CINFrontFaceFile, setCINFrontFaceFile] = useState<File | null>(null);
  const [CINBackFaceFile, setCINBackFaceFile] = useState<File | null>(null);

  const { control, handleSubmit } = useForm<Customer>({
    defaultValues: customer,
    resolver: yupResolver(schema),
  });

  const updateCustomer = useUpdateCustomer();

  const onHandleSubmit = async (customerDetails: Customer) => {
    setLoading(true);

    try {
      await updateCustomer({
        CINFrontFaceFile,
        CINBackFaceFile,
        DriverLicenseFrontFaceFile: null,
        DriverLicenseBackFaceFile: null,
        customer: {
          ...customer,
          cin: customerDetails.cin,
        },
      });

      await queryClient.invalidateQueries([
        queryKeysConstants.customers,
        customer.id,
      ]);

      onShowToast("CIN Details Updated", false);

      setCINFrontFaceFile(null);
      setCINBackFaceFile(null);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("cin")}
          </Text>
          <Button
            variant="plain"
            onClick={() =>
              setEdit((prevState: boolean) => {
                if (!prevState) {
                  setCINFrontFaceFile(null);
                  setCINBackFaceFile(null);
                }

                return !prevState;
              })
            }
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <BlockStack gap="200">
            <p>
              {t("cin")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {customer.cin || "-"}
              </Text>
            </p>
            <InlineStack align="space-between" gap="100">
              <p className="w50">
                {t("front_face")}
                <br />
                <div className="afaLZ h200 p20">
                  <div className="qT8wK">
                    <img
                      className="llVfq"
                      src={
                        customer.cin_card?.front_public_url ||
                        "https://placehold.co/600x400/FFF/000000/?text=No%20Card"
                      }
                      alt="CIN Front"
                    />
                  </div>
                </div>
              </p>
              <p className="w50">
                {t("back_face")}
                <br />
                <div className="afaLZ h200 p20">
                  <div className="qT8wK">
                    <img
                      className="llVfq"
                      src={
                        customer.cin_card?.back_public_url ||
                        "https://placehold.co/600x400/FFF/000000/?text=No%20Card"
                      }
                      alt="CIN Back"
                    />
                  </div>
                </div>
              </p>
            </InlineStack>
          </BlockStack>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <TextFieldInput control={control} name="cin" label="CIN" />
              <FormLayout.Group>
                <ImageFileUpload
                  label={t("front_face")}
                  file={CINFrontFaceFile}
                  setFile={setCINFrontFaceFile}
                />
                <ImageFileUpload
                  label={t("back_face")}
                  file={CINBackFaceFile}
                  setFile={setCINBackFaceFile}
                />
              </FormLayout.Group>
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
