import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { CarDocument } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import FileUpload from "../../../../../componenets/FileUpload";
import { useAddCarDocument } from "./hooks/useAddCarDocument";

interface Props {
  carId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export default function AddCarDocumentModal({
  carId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm<CarDocument>({
    defaultValues: {
      added_at: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const [file, setFile] = useState<File | null>(null);
  const addCarDocument = useAddCarDocument(carId);

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (carDocument: CarDocument) => {
    if (!file) {
      onShowToast("Document file is required", true);

      return;
    }

    setLoading(true);

    try {
      await addCarDocument({ carDocument, documentFile: file });

      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "car-documents",
      ]);

      setFile(null);
      reset({ name: "" });

      handleClose();

      onShowToast("Car Document Saved", false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      title={t("add_document")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction() {
            setFile(null);
            reset({ name: "" });

            handleClose();
          },
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="name" label={t("name")} />
          <FileUpload label={t("upload")} file={file} setFile={setFile} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
