import {
  BlockStack,
  Card,
  InlineGrid,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextContainer,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { useRecurringCharge } from "./hooks/useRecurringCharge";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import RecurringChargePayments from "./componenets/RecurringChargePayments/RecurringChargePayments";
import DeleteConfirmationDialog from "../../../componenets/DeleteConfirmationDialog";

import { useDeleteRecurringCharge } from "./hooks/useDeleteRecurringCharge";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";

export default function RecurringChargePage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const navigate = useNavigate();
  const { recurringChargeId } = useParams();
  const { t } = useTranslation();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { recurringCharge, isLoading, isError } = useRecurringCharge(
    recurringChargeId as string,
  );
  const deleteRecurringCharge = useDeleteRecurringCharge(
    recurringChargeId as string,
  );

  const onHandleDelete = async () => {
    setDeleteLoading(true);

    try {
      await deleteRecurringCharge();

      onShowToast("Recurring Charge Deleted", false);
      setDeleteDialogOpen(false);

      navigate("/admin/recurring_charges");

      await queryClient.invalidateQueries([
        queryKeysConstants.recurring_charges,
      ]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setDeleteLoading(false);
    setDeleteDialogOpen(false);
  };
  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  if (isError) {
    navigate("/admin/recurring_charges");
  }

  return (
    <Page
      fullWidth
      title={recurringCharge?.label}
      backAction={{
        onAction: () => navigate("/admin/recurring_charges"),
      }}
      primaryAction={{
        content: t("delete"),
        destructive: true,
        onAction: () => setDeleteDialogOpen(true),
        loading: deleteLoading,
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <InlineGrid columns={{ sm: 2, md: 4, lg: 5 }}>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("amount")}
                </Text>
                {formatMoney(recurringCharge?.amount || 0)}
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("cycle")}
                </Text>
                {t(recurringCharge?.cycle.toLowerCase() || "every_month")}
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("next_charge_at")}
                </Text>
                {recurringCharge?.next_charge_at
                  ? formatDate(recurringCharge?.next_charge_at, true)
                  : "-"}
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("theoretical_last_charge_at")}
                </Text>
                {recurringCharge?.theoretical_last_charge_at
                  ? formatDate(
                      recurringCharge?.theoretical_last_charge_at,
                      true,
                    )
                  : "-"}
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("last_charge_at")}
                </Text>
                {recurringCharge?.last_charge_at
                  ? formatDate(recurringCharge?.last_charge_at, true)
                  : "-"}
              </BlockStack>
            </InlineGrid>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <RecurringChargePayments
            recurringChargeId={recurringChargeId as string}
          />
        </Layout.Section>
      </Layout>
      <DeleteConfirmationDialog
        name="this recurring charge"
        open={deleteDialogOpen}
        loading={deleteLoading}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => onHandleDelete()}
      />
    </Page>
  );
}
