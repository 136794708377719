import { Modal } from "@shopify/polaris";
import React from "react";

interface DeleteConfirmationDialogProps {
  name: string;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onDelete: () => void;
}
export default function DeleteConfirmationDialog({
  name,
  open,
  loading,
  onClose,
  onDelete,
}: DeleteConfirmationDialogProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Confirmation"
      primaryAction={{
        content: "Delete",
        disabled: loading,
        loading,
        onAction: onDelete,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          disabled: loading,
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>Are you sure you want to delete {name}?</Modal.Section>
    </Modal>
  );
}
