import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { InsuranceStats } from "../../../../types/common.types";

async function getInsuranceStats(): Promise<InsuranceStats> {
  const { data } = await axiosInstance.get(`/insurance`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface UseInsuranceStats {
  isLoading: boolean;
  stats: InsuranceStats;
}

export function useInsuranceStats(): UseInsuranceStats {
  const { data, isLoading } = useQuery(
    [queryKeysConstants.insurance],
    () => getInsuranceStats(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    stats: data || { amount_paid: 0, amount_left: 0 },
  };
}
