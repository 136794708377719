import {
  FormLayout,
  Layout,
  Page,
  Text,
  Card,
  BlockStack,
} from "@shopify/polaris";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../types/common.types";
import TextFieldInput from "../../../componenets/TextFieldInput";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useAddCustomer } from "./hooks/useAddCustomer";
import ImageFileUpload from "../../../componenets/ImageFileUpload";

const schema = yup
  .object({
    name: yup.string().required(),
    address: yup.string().nullable(),
    phone: yup.string().required(),
    cin: yup.string().nullable(),
    driver_license_number: yup.string().required(),
  })
  .required();

export default function NewCustomerPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [CINFrontFaceFile, setCINFrontFaceFile] = useState<File | null>(null);
  const [CINBackFaceFile, setCINBackFaceFile] = useState<File | null>(null);

  const [DriverLicenseFrontFaceFile, setDriverLicenseFrontFaceFile] =
    useState<File | null>(null);
  const [DriverLicenseBackFaceFile, setDriverLicenseBackFaceFile] =
    useState<File | null>(null);

  const { control, handleSubmit } = useForm<Customer>({
    resolver: yupResolver(schema),
  });

  const addCustomer = useAddCustomer();

  const onHandleSubmit = async (customer: Customer) => {
    if (!DriverLicenseFrontFaceFile) {
      onShowToast("Driver License images are required.", true);

      return;
    }

    setLoading(true);

    try {
      const newCustomer = await addCustomer({
        CINFrontFaceFile,
        CINBackFaceFile,
        DriverLicenseFrontFaceFile,
        DriverLicenseBackFaceFile,
        customer,
      });
      await queryClient.invalidateQueries([queryKeysConstants.customers]);

      onShowToast("Customer Saved", false);

      console.log(navigate);

      navigate(`/admin/customers/${newCustomer.id}`);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Page
      title={t("new_customer")}
      fullWidth
      backAction={{
        url: "/admin/customers",
      }}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("customer_details")}
              </Text>
              <FormLayout>
                <TextFieldInput
                  control={control}
                  name="name"
                  label={t("name")}
                />
                <TextFieldInput
                  control={control}
                  name="address"
                  label={t("address")}
                />
                <TextFieldInput
                  control={control}
                  name="phone"
                  label={t("phone")}
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("driver_license")}
              </Text>
              <FormLayout>
                <TextFieldInput
                  control={control}
                  name="driver_license_number"
                  label={t("driver_license_number")}
                />
                <FormLayout.Group>
                  <ImageFileUpload
                    label={t("front_face")}
                    file={DriverLicenseFrontFaceFile}
                    setFile={setDriverLicenseFrontFaceFile}
                  />
                  <ImageFileUpload
                    label={t("back_face")}
                    file={DriverLicenseBackFaceFile}
                    setFile={setDriverLicenseBackFaceFile}
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("cin")}
              </Text>
              <FormLayout>
                <TextFieldInput control={control} name="cin" label={t("cin")} />
                <FormLayout.Group>
                  <ImageFileUpload
                    label={t("front_face")}
                    file={CINFrontFaceFile}
                    setFile={setCINFrontFaceFile}
                  />
                  <ImageFileUpload
                    label={t("back_face")}
                    file={CINBackFaceFile}
                    setFile={setCINBackFaceFile}
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
