import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import {
  DatePicker,
  TextField,
  Icon,
  Popover,
  ResourceList,
  Box,
  InlineStack,
} from "@shopify/polaris";
import { CalendarIcon, ClockIcon } from "@shopify/polaris-icons";
import { timezoneOffsetDate } from "../helpers/helpers";

const toInt = (time) =>
  ((h, m) => h * 2 + m / 30)(...time.split(":").map(parseFloat));
const toTime = (int) => [Math.floor(int / 2), int % 2 ? "30" : "00"].join(":");
const range = (from, to) =>
  Array(to - from + 1)
    .fill()
    .map((_, i) => from + i);
const eachHalfHour = (t1, t2) => range(...[t1, t2].map(toInt)).map(toTime);

const timeList = eachHalfHour("00:00", "23:30");

const getDateString = (d: Date) => {
  const date = timezoneOffsetDate(d);

  return date.toISOString().split("T")[0];
};

const today = new Date();

interface DateTimePickerProps {
  selectedDates: any;
  setSelectedDates: Dispatch<SetStateAction<{ start: Date; end: Date }>>;
  selectedTime: any;
  setSelectedTime: Dispatch<SetStateAction<string>>;
  dateLabel?: string;
  timeLabel?: string;
  onChange?: (v: Date) => void;
}

export const DateTimePicker = ({
  selectedDates,
  setSelectedDates,
  selectedTime,
  setSelectedTime,
  dateLabel = "Date",
  timeLabel = "Time",
  onChange = () => {},
}: DateTimePickerProps) => {
  const isInitialMount = useRef(true);

  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const [datePopoverActive, setDatePopoverActive] = useState(false);
  const [timePopoverActive, setTimePopoverActive] = useState(false);

  const dateString = getDateString(selectedDates.start);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (selectedTime && selectedDates.start) {
      onChange(
        new Date(`${getDateString(selectedDates.start)} ${selectedTime}`),
      );
    }
  }, [selectedDates, selectedTime]);

  const onDateChange = useCallback((v) => {
    setSelectedDates(v);
    setDatePopoverActive(false);
  }, []);

  const onTimeSelect = useCallback((v) => {
    setSelectedTime(v);
    setTimePopoverActive(false);
  }, []);

  const toggleDatePopoverActive = useCallback(
    () => setDatePopoverActive((v) => !v),
    [],
  );

  const toggleTimePopoverActive = useCallback(
    () => setTimePopoverActive((v) => !v),
    [],
  );

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  );

  const dateActivator = (
    <TextField
      label={dateLabel}
      value={dateString}
      readOnly
      prefix={<Icon source={CalendarIcon} />}
      onFocus={toggleDatePopoverActive}
      autoComplete=""
    />
  );

  const timeActivator = (
    <TextField
      label={timeLabel}
      value={selectedTime}
      readOnly
      prefix={<Icon source={ClockIcon} />}
      onFocus={toggleTimePopoverActive}
      autoComplete=""
    />
  );

  return (
    <InlineStack align="space-between" gap="100">
      <Box minWidth="270px" width="49%">
        <Popover
          autofocusTarget="none"
          fullWidth
          preferInputActivator={false}
          preferredPosition="below"
          active={datePopoverActive}
          activator={dateActivator}
          onClose={toggleDatePopoverActive}
        >
          <div style={{ padding: "16px" }}>
            <DatePicker
              month={month}
              year={year}
              onChange={onDateChange}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
            />
          </div>
        </Popover>
      </Box>

      <Box minWidth="270px" width="49%">
        <Popover
          preferredPosition="above"
          active={timePopoverActive}
          activator={timeActivator}
          onClose={toggleTimePopoverActive}
        >
          <div style={{ minWidth: "270px" }}>
            <ResourceList
              items={timeList}
              renderItem={(time) => (
                <ResourceList.Item id={time} onClick={onTimeSelect}>
                  {time}
                </ResourceList.Item>
              )}
            />
          </div>
        </Popover>
      </Box>
    </InlineStack>
  );
};
