import {
  Card,
  IndexTable,
  InlineStack,
  Link,
  Page,
  Pagination,
  Text,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecurringCharge } from "../../../types/common.types";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import NewRecurringChargeModal from "./NewRecurringChargeModal";
import { useRecurringCharges } from "./hooks/useRecurringCharges";

export default function RecurringChargesPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newChargeModalActive, setNewChargeModalActive] = useState(false);

  const columns = [
    { label: t("label") },
    { label: t("amount") },
    { label: t("cycle") },
    { label: t("next_charge_at") },
  ];

  const { minPage, maxPage, isFetching, page, setPage, recurringCharges } =
    useRecurringCharges(10);

  const rowMarkup = recurringCharges.map(
    (recurringCharge: RecurringCharge, index) => (
      <IndexTable.Row
        id={recurringCharge.id as string}
        key={recurringCharge.id}
        position={index}
      >
        <IndexTable.Cell>
          {" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            dataPrimaryLink
            onClick={() =>
              navigate(`/admin/recurring_charges/${recurringCharge.id}`)
            }
          >
            <Text fontWeight="bold" as="span">
              {recurringCharge.label}
            </Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>{formatMoney(recurringCharge.amount)}</IndexTable.Cell>
        <IndexTable.Cell>
          {t(recurringCharge.cycle.toLowerCase())}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {recurringCharge.next_charge_at
            ? formatDate(recurringCharge.next_charge_at, true, true)
            : "-"}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Page
      title={t("recurring_charges")}
      fullWidth
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setNewChargeModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexTable
          resourceName={{
            singular: t("recurring_charge"),
            plural: t("recurring_charges"),
          }}
          itemCount={recurringCharges.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
        <NewRecurringChargeModal
          active={newChargeModalActive}
          setActive={setNewChargeModalActive}
        />
      </Card>
    </Page>
  );
}
