import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Customer } from "../../../../types/common.types";

async function addCustomer(
  customer: Customer,
  CINFrontFaceFile: File | null,
  CINBackFaceFile: File | null,
  DriverLicenseFrontFaceFile: File,
  DriverLicenseBackFaceFile: File | null,
): Promise<Customer> {
  const form = new FormData();

  if (CINFrontFaceFile && CINBackFaceFile) {
    form.append("cin_front_face_file", CINFrontFaceFile);
    form.append("cin_back_face_file", CINBackFaceFile);
  }

  form.append("driver_license_front_face_file", DriverLicenseFrontFaceFile);

  if (DriverLicenseBackFaceFile) {
    form.append("driver_license_back_face_file", DriverLicenseBackFaceFile);
  }

  const { data } = await axiosInstance.post(`/customers/`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: customer,
  });

  return data;
}

interface Props {
  customer: Customer;
  DriverLicenseFrontFaceFile: File;
  DriverLicenseBackFaceFile: File | null;
  CINFrontFaceFile: File | null;
  CINBackFaceFile: File | null;
}

export function useAddCustomer(): UseMutateAsyncFunction<
  Customer,
  unknown,
  Props,
  unknown
> {
  const { mutateAsync } = useMutation(
    ({
      customer,
      CINFrontFaceFile,
      CINBackFaceFile,
      DriverLicenseFrontFaceFile,
      DriverLicenseBackFaceFile,
    }: Props) =>
      addCustomer(
        customer,
        CINFrontFaceFile,
        CINBackFaceFile,
        DriverLicenseFrontFaceFile,
        DriverLicenseBackFaceFile,
      ),
  );

  return mutateAsync;
}
