import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteRent(rentId: string): Promise<void> {
  return axiosInstance.delete(`/rents/${rentId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteRent(): UseMutateAsyncFunction<
  void,
  unknown,
  string,
  unknown
> {
  const { mutateAsync } = useMutation((rentId: string) => deleteRent(rentId));

  return mutateAsync;
}
