import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Rent } from "../../../../types/common.types";

async function updateRent(
  contractFile: File | null,
  rent: Rent,
): Promise<Rent> {
  const form = new FormData();

  if (contractFile) {
    form.append("contract_file", contractFile);
  }

  form.append("padding", "not-needed");

  return axiosInstance.put(`/rents/${rent.id}`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: rent,
  });
}

interface Props {
  contractFile: File | null;
  rent: Rent;
}

export function useUpdateRent(): UseMutateAsyncFunction<
  Rent,
  unknown,
  Props,
  unknown
> {
  const { mutateAsync } = useMutation(({ contractFile, rent }: Props) =>
    updateRent(contractFile, rent),
  );

  return mutateAsync;
}
