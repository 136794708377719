import {
  Card,
  IndexFilters,
  IndexTable,
  InlineStack,
  Link,
  Page,
  Pagination,
  Text,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { useCustomers } from "./hooks/useCustomers";
import { Customer } from "../../../types/common.types";

export default function CustomersPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode();

  const [queryValue, setQueryValue] = useState("");
  const [query] = useDebounce([queryValue], 1000);
  const { minPage, maxPage, isFetching, page, setPage, customers } =
    useCustomers(10, query);

  const columns = [
    { label: t("name") },
    { label: t("driver_license_number") },
    { label: t("cin") },
    { label: t("phone") },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const rowMarkup = customers.map((customer: Customer, index) => (
    <IndexTable.Row
      id={customer.id as string}
      key={customer.id}
      position={index}
    >
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          onClick={() => navigate(`/admin/customers/${customer.id}`)}
        >
          <Text fontWeight="bold" as="span">
            {customer.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{customer.driver_license_number}</IndexTable.Cell>
      <IndexTable.Cell>{customer.cin || "-"}</IndexTable.Cell>
      <IndexTable.Cell>{customer.phone}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("customers")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          navigate("/admin/customers/new");
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          queryValue={queryValue}
          queryPlaceholder={t("searching")}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          cancelAction={{
            onAction: () => setQueryValue(""),
            disabled: false,
            loading: false,
          }}
          tabs={[
            {
              id: "all",
              content: t("all"),
            },
          ]}
          selected={0}
          onSelect={() => {}}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("customer"),
            plural: t("customers"),
          }}
          itemCount={customers.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
    </Page>
  );
}
