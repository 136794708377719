import { BlockStack, Card, Text } from "@shopify/polaris";
import { BarChart } from "@shopify/polaris-viz";
import { ChartState } from "@shopify/polaris-viz-core/build/ts/types";
import { MonthlyStats } from "../../../../types/common.types";

export default function NumberOfRentedDaysPerCarChart({
  stats,
  isLoading,
}: {
  stats: MonthlyStats;
  isLoading: boolean;
}) {
  const name = "Rented days per car in current month";

  return (
    <Card>
      <BlockStack gap="800">
        <Text as="h2" variant="bodySm">
          {name}
        </Text>
        <div style={{ height: "20rem" }}>
          <BarChart
            showLegend={false}
            data={[
              {
                name,
                data:
                  stats?.number_of_rented_days_in_period.map((stat) => ({
                    key: stat.name,
                    value: stat.value,
                  })) || [],
              },
            ]}
            theme="Light"
            state={
              isLoading ? ("Loading" as ChartState) : ("Success" as ChartState)
            }
          />
        </div>
      </BlockStack>
    </Card>
  );
}
