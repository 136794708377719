import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { RecurringChargePayment } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addRecurringChargePayment(
  recurringChargeId: string,
  payment: RecurringChargePayment,
  invoiceFile: File | null,
): Promise<RecurringChargePayment> {
  const form = new FormData();

  if (invoiceFile) {
    form.append("file", invoiceFile);
  } else {
    form.append("no-file", "true");
  }

  return axiosInstance.post(
    `/recurring_charges/${recurringChargeId}/payments`,
    form,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      params: payment,
    },
  );
}

export interface Props {
  payment: RecurringChargePayment;
  invoiceFile: File | null;
}

export function useAddRecurringChargePayment(
  recurringChargeId: string,
): UseMutateAsyncFunction<RecurringChargePayment, unknown, Props, unknown> {
  const { mutateAsync } = useMutation(({ payment, invoiceFile }: Props) =>
    addRecurringChargePayment(recurringChargeId, payment, invoiceFile),
  );

  return mutateAsync;
}
