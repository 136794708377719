import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  Link,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "@shopify/polaris-icons";
import { useCarDocuments } from "./hooks/useCarDocuments";
import { CarDocument } from "../../../../../types/common.types";
import { formatDate } from "../../../../../helpers/helpers";
import AddCarDocumentModal from "./AddCarDocumentModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteDocuments } from "./hooks/useDeleteDocuments";

export default function CarDocuments({ carId }: { carId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const columns = [{ label: t("name") }, { label: t("added_at") }];

  const [addCarDocumentModalActive, setAddCarDocumentModalActive] =
    useState(false);
  const { carDocuments, isFetching } = useCarDocuments(carId, 10);
  const deleteDocuments = useDeleteDocuments(carId as string);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    carDocuments.map((document: CarDocument) => ({ ...document })),
  );

  const handleDelete = async () => {
    onShowToast("Deleting Documents", false);

    try {
      await deleteDocuments(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "car-documents",
      ]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = carDocuments.map(
    (carDocument: CarDocument, index: number) => (
      <IndexTable.Row
        id={carDocument.id as string}
        key={carDocument.id}
        position={index}
        selected={selectedResources.includes(carDocument.id as string)}
      >
        <IndexTable.Cell>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link target="_blank" url={carDocument.file_public_url as string}>
            {carDocument.name}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatDate(carDocument.added_at, true, true)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("documents")}
            </Text>
            <Button
              variant="plain"
              onClick={() => setAddCarDocumentModalActive(true)}
            >
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("document"),
            plural: t("documents"),
          }}
          itemCount={carDocuments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          bulkActions={[
            {
              icon: DeleteIcon,
              destructive: true,
              content: t("delete_documents"),
              onAction: handleDelete,
            },
          ]}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <AddCarDocumentModal
        carId={carId}
        active={addCarDocumentModalActive}
        setActive={setAddCarDocumentModalActive}
      />
    </Card>
  );
}
