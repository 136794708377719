import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteCars(carsIds: string[]): Promise<void> {
  return axiosInstance.delete(`/cars/?ids=${carsIds.join(",")}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteCars(): UseMutateAsyncFunction<
  void,
  unknown,
  string[],
  unknown
> {
  const { mutateAsync } = useMutation((carsIds: string[]) =>
    deleteCars(carsIds),
  );

  return mutateAsync;
}
