import "@shopify/polaris/build/esm/styles.css";
import "@shopify/polaris-viz/build/esm/styles.css";
import { Spinner } from "@shopify/polaris";
import { Navigate, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAuthState } from "react-firebase-hooks/auth";
import { NotProtected, Protected } from "./componenets/AuthHelpers";
import Auth from "./layout/Auth";
import AdminDashboard from "./layout/AdminDashboard";
import { auth } from "./services/firebase.service";

function App() {
  const [user, loading, error] = useAuthState(auth);
  const isLoggedIn = !!user && !error;

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <>
      <Routes>
        <Route
          path="/auth/*"
          element={
            <NotProtected isLoggedIn={isLoggedIn} redirectTo="/admin/analytics">
              <Auth />
            </NotProtected>
          }
        />
        <Route
          path="/admin/*"
          element={
            <Protected isLoggedIn={isLoggedIn} redirectTo="/auth/login">
              <AdminDashboard />
            </Protected>
          }
        />
        <Route
          path="*"
          element={
            <Navigate to={isLoggedIn ? "/admin/analytics" : "/auth/login"} />
          }
        />
      </Routes>
      <ReactQueryDevtools />
    </>
  );
}

export default App;
