import {
  BlockStack,
  Card,
  InlineGrid,
  Layout,
  Link,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  TextContainer,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRent } from "./hooks/useRent";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useDeleteRent } from "./hooks/useDeleteRent";
import DeleteConfirmationDialog from "../../../componenets/DeleteConfirmationDialog";
import RentDetails from "./components/RentDetails";
import { Rent } from "../../../types/common.types";
import ContractDetails from "./components/ContractDetails";
import RentPayments from "./components/RentPayments/RentPayments";
import { formatMoney } from "../../../helpers/helpers";

export default function RentPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);

  const { rentId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { rent, isLoading, isError } = useRent(rentId as string);

  const deleteRent = useDeleteRent();

  const handleDeleteRent = async () => {
    setDeleteLoading(true);

    try {
      await deleteRent(rent?.id as string);

      onShowToast("Rent Deleted", false);
      setDeleteDialogOpen(false);

      navigate("/admin/rents");

      await queryClient.invalidateQueries([queryKeysConstants.rents]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setDeleteLoading(false);
    setDeleteDialogOpen(false);
  };

  if (isError) {
    navigate("/admin/rents");
  }

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      fullWidth
      title={t("rent")}
      backAction={{
        onAction: () => navigate("/admin/rents"),
      }}
      primaryAction={{
        content: t("delete"),
        destructive: true,
        loading: deleteLoading,
        onAction: () => setDeleteDialogOpen(true),
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <InlineGrid columns={3}>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("customer")}
                </Text>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link url={`/admin/customers/${rent?.customer?.id}`}>
                  {rent?.customer?.name}
                </Link>
              </BlockStack>
              {rent?.second_driver_id && (
                <BlockStack>
                  <Text as="span" fontWeight="bold">
                    {t("second_driver")}
                  </Text>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link url={`/admin/customers/${rent?.second_driver_id}`}>
                    {rent?.second_driver?.name}
                  </Link>
                </BlockStack>
              )}
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("car")}
                </Text>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link url={`/admin/cars/${rent?.car?.id}`}>
                  {`${rent?.car?.name} (${rent?.car?.license_plate})`}
                </Link>
              </BlockStack>
            </InlineGrid>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <InlineGrid columns={2}>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("amount_paid")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(rent?.amount_paid as number)}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("amount_left")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(rent?.amount_left as number)}
                </Text>
              </BlockStack>
            </InlineGrid>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <ContractDetails rent={rent as Rent} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <RentDetails rent={rent as Rent} />
        </Layout.Section>
        <Layout.Section>
          <RentPayments rentId={rent?.id as string} />
        </Layout.Section>
      </Layout>
      <DeleteConfirmationDialog
        name="this rent"
        open={deleteDialogOpen}
        loading={deleteLoading}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => handleDeleteRent()}
      />
    </Page>
  );
}
