import {
  FormLayout,
  Layout,
  Page,
  Text,
  Card,
  BlockStack,
  Checkbox,
  TextField,
} from "@shopify/polaris";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Car, Customer, Rent } from "../../../types/common.types";
import DateTimePickerInput from "../../../componenets/DateTimePickerInput";
import TextFieldInput from "../../../componenets/TextFieldInput";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useCars } from "../Cars/hooks/useCars";
import { useAddRent } from "./hooks/useAddRent";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import SelectInput from "../../../componenets/SelectInput";
import { useCustomers } from "../Customers/hooks/useCustomers";
import { addDays, formatDate } from "../../../helpers/helpers";
import ImageFileUpload from "../../../componenets/ImageFileUpload";
import CheckboxInput from "../../../componenets/CheckboxInput";

const schema = yup
  .object({
    customer_id: yup.string().required(),
    second_driver_id: yup.string().nullable(),
    from_date: yup.date().required(),
    to_date: yup.date().required(),
    car_id: yup.string().required(),
    is_flat_fee_rent: yup.boolean(),
    price_per_day: yup.number().min(0).required(),
    number_of_days: yup.number().min(1).required(),
    total: yup.number().min(0).required(),
    amount_paid: yup.number().min(0).required(),
    amount_left: yup.number().min(0).required(),
  })
  .required();

export default function NewRentPage() {
  const navigate = useNavigate();
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [contractFile, setContractFile] = useState<File | null>(null);

  const { control, handleSubmit, watch, setValue, getValues } = useForm<Rent>({
    defaultValues: {
      second_driver_id: null,
      number_of_days: 1,
      total: 0,
      amount_paid: 0,
      amount_left: 0,
      from_date: new Date(),
      to_date: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const watchCarId = watch("car_id");
  const watchIsFlatFeeRent = watch("is_flat_fee_rent");
  const watchPricePerDay = watch("price_per_day");
  const watchTotal = watch("total");
  const watchFromDate = watch("from_date");
  const watchToDate = watch("to_date");
  const watchNumberOfDays = watch("number_of_days");
  const watchAmountPaid = watch("amount_paid");

  const { isLoading: isCustomersLoading, customers } = useCustomers(100);
  const { isLoading: isCarsLoading, cars } = useCars(100);

  const [hasSecondDriver, setHasSecondDriver] = useState(false);

  const addRent = useAddRent();

  const onHandleSubmit = async (rent: Rent) => {
    if (!contractFile) {
      onShowToast("Please make sure to scan and upload the contract.", true);

      return;
    }

    setLoading(true);

    try {
      const newRent: Rent = await addRent({
        contractFile: contractFile as File,
        rent,
      });
      await queryClient.invalidateQueries([queryKeysConstants.rents]);

      onShowToast("Rent Saved", false);

      navigate(`/admin/rents/${newRent.id}`);
      console.log(navigate);
    } catch (e: any) {
      onShowToast(e.response.data.detail, true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  const getCarById = (carId: string | undefined) => {
    const car = cars.filter((car: Car) => car.id === carId, null);

    if (!car.length) {
      return cars[0];
    }

    return car[0];
  };

  useEffect(() => {
    if (customers.length) setValue("customer_id", customers[0].id as string);
  }, [customers]);

  useEffect(() => {
    if (cars.length) setValue("car_id", cars[0].id as string);
  }, [cars]);

  useEffect(() => {
    const car = getCarById(watchCarId);

    if (!car) return;

    setValue("price_per_day", car.min_daily_rate);
  }, [watchCarId]);

  useEffect(() => {
    if (getValues("is_flat_fee_rent")) {
      return;
    }

    const pricePerDay: number = getValues("price_per_day") || 0;
    const numberOfDays: number = getValues("number_of_days") || 0;

    setValue("total", pricePerDay * numberOfDays);
  }, [watchPricePerDay, watchNumberOfDays]);

  useEffect(() => {
    const numberOfDays: number = getValues("number_of_days") || 1;
    const fromDate = getValues("from_date");

    setValue("to_date", addDays(fromDate as Date, numberOfDays));
  }, [watchNumberOfDays, watchFromDate]);

  useEffect(() => {
    const amountPaid: number = getValues("amount_paid") || 0;
    const total: number = getValues("total") || 0;

    setValue("amount_left", total - amountPaid);

    if (!getValues("is_flat_fee_rent")) {
      return;
    }

    const numberOfDays: number = getValues("number_of_days") || 1;

    setValue("price_per_day", total / numberOfDays);
  }, [watchTotal]);

  useEffect(() => {
    if (!hasSecondDriver) {
      setValue("second_driver_id", null);
    } else {
      setValue("second_driver_id", customers[0].id);
    }
  }, [hasSecondDriver]);

  useEffect(() => {
    const amountPaid: number = getValues("amount_paid") || 0;
    const total: number = getValues("total") || 0;

    setValue("amount_left", total - amountPaid);
  }, [watchAmountPaid]);

  return (
    <Page
      title={t("new_rent")}
      fullWidth
      backAction={{
        url: "/admin/rents",
      }}
      primaryAction={{
        content: t("save"),
        disabled: isCarsLoading || isCustomersLoading || loading,
        loading: isCarsLoading || isCustomersLoading || loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("customer_details")}
              </Text>
              <FormLayout>
                <SelectInput
                  control={control}
                  name="customer_id"
                  label={t("customer")}
                  options={customers.map((customer: Customer) => ({
                    label: `${customer.name} (${customer.driver_license_number})`,
                    value: customer.id,
                  }))}
                />
                <Checkbox
                  label={t("has_second_driver")}
                  checked={hasSecondDriver}
                  onChange={(value: boolean) => setHasSecondDriver(value)}
                />
                {hasSecondDriver && (
                  <SelectInput
                    control={control}
                    name="second_driver_id"
                    label={t("second_driver")}
                    options={customers.map((customer: Customer) => ({
                      label: `${customer.name} (${customer.driver_license_number})`,
                      value: customer.id,
                    }))}
                  />
                )}
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("rent_details")}
              </Text>
              <FormLayout>
                <SelectInput
                  control={control}
                  name="car_id"
                  label={t("car")}
                  options={cars.map((car: Car) => ({
                    label: `${car.name} (${car.license_plate})`,
                    value: car.id,
                  }))}
                />
                <CheckboxInput
                  control={control}
                  name="is_flat_fee_rent"
                  label={t("is_flat_fee_rent")}
                />
                <FormLayout.Group>
                  {!watchIsFlatFeeRent && (
                    <TextFieldInput
                      control={control}
                      name="price_per_day"
                      label={t("price_per_day")}
                      type="number"
                    />
                  )}
                  <TextFieldInput
                    control={control}
                    name="number_of_days"
                    label={t("number_of_days")}
                    type="number"
                  />
                  <TextFieldInput
                    control={control}
                    name="total"
                    label={t("total")}
                    type="number"
                    disabled={!watchIsFlatFeeRent}
                  />
                </FormLayout.Group>
                <DateTimePickerInput
                  control={control}
                  name="from_date"
                  dateLabel={t("from_date")}
                  timeLabel={t("from_time")}
                />
                <TextField
                  value={formatDate(watchToDate, false, true)}
                  label={t("to_date")}
                  disabled
                  autoComplete=""
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap="500">
              <Text as="span" fontWeight="bold">
                {t("payment_details")}
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <TextFieldInput
                    control={control}
                    name="amount_paid"
                    label={t("amount_paid")}
                    type="number"
                  />
                  <TextFieldInput
                    control={control}
                    name="amount_left"
                    label={t("amount_left")}
                    type="number"
                    disabled
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <BlockStack gap="500">
              <Text as="span" fontWeight="bold">
                {t("contract")}
              </Text>
              <ImageFileUpload
                label={t("upload")}
                file={contractFile}
                setFile={setContractFile}
              />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
