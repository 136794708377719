import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { Agent } from "../../../types/common.types";
import { useAddAgent } from "./hooks/useAddAgent";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import SelectInput from "../../../componenets/SelectInput";

interface CarModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().required(),
    salary: yup.number().required(),
    type: yup.string().required(),
  })
  .required();

export default function NewAgentModal({ active, setActive }: CarModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<Agent>({
    defaultValues: {
      salary: 0,
      type: "AGENT",
    },
    resolver: yupResolver(schema),
  });

  const addAgent = useAddAgent();

  const handleClose = () => {
    reset({
      name: "",
      email: "",
      salary: 0,
      type: "AGENT",
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (agent: Agent) => {
    setLoading(true);

    try {
      await addAgent(agent);

      await queryClient.invalidateQueries([queryKeysConstants.agents]);

      handleClose();

      onShowToast(t("agent_saved"), false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_agent")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label={t("name")}
            name="name"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("email")}
            name="email"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("salary")}
            name="salary"
            type="number"
          />
          <SelectInput
            control={control}
            name="type"
            label={t("type")}
            options={[
              {
                label: t("owner"),
                value: "OWNER",
              },
              {
                label: t("agent"),
                value: "AGENT",
              },
            ]}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
