import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteRecurringCharge(recurringChargeId: string): Promise<void> {
  return axiosInstance.delete(`/recurring_charges/${recurringChargeId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteRecurringCharge(
  recurringChargeId: string,
): UseMutateAsyncFunction<void, unknown, void, unknown> {
  const { mutateAsync } = useMutation(() =>
    deleteRecurringCharge(recurringChargeId),
  );

  return mutateAsync;
}
