import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Agent } from "../../../../types/common.types";

async function addAgent(agent: Agent): Promise<Agent> {
  return axiosInstance.post(`/agents/`, agent, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddAgent(): UseMutateAsyncFunction<
  Agent,
  unknown,
  Agent,
  unknown
> {
  const { mutateAsync } = useMutation((agent: Agent) => addAgent(agent));

  return mutateAsync;
}
