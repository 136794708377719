import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function getReservationAttestation(reservationId: string): Promise<any> {
  return axiosInstance.get(`/reservations/${reservationId}/attestation`, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDownloadReservationAttestation(
  reservationId: string,
): UseMutateAsyncFunction<any, unknown, void, unknown> {
  const { mutateAsync } = useMutation(() =>
    getReservationAttestation(reservationId),
  );

  return mutateAsync;
}
