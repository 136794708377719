import { Page, Spinner, InlineGrid } from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useTranslation } from "react-i18next";
import { OverAllStats } from "../../../types/common.types";
import { useOverAllStats } from "./hooks/useOverAllStats";
import AverageMonthlyCostPerCarChart from "./components/AverageMonthlyCostPerCarChart";

export default function OverAllStatsPage() {
  const { t } = useTranslation();
  const { overAllStats, isLoading } = useOverAllStats();

  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <PolarisVizProvider>
      <Page fullWidth title={t("analytics")}>
        <InlineGrid columns={{ sm: 1, md: 2, xl: 3 }} gap="200">
          <AverageMonthlyCostPerCarChart
            stats={overAllStats as OverAllStats}
            isLoading={isLoading}
          />
        </InlineGrid>
      </Page>
    </PolarisVizProvider>
  );
}
