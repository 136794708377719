import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  useIndexResourceState,
  Link,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { DeleteIcon } from "@shopify/polaris-icons";
import { formatDate, formatMoney } from "../../../../../helpers/helpers";
import { RecurringChargePayment } from "../../../../../types/common.types";
import { useRecurringChargePayments } from "./hooks/useRecurringChargePayments";
import NewRecurringChargePaymentModal from "./NewRecurringChargePaymentModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteRecurringPaymentCharges } from "./hooks/useDeleteRecurringPaymentCharges";

export default function RecurringChargePayments({
  recurringChargeId,
}: {
  recurringChargeId: string;
}) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const columns = [
    { label: t("date") },
    { label: t("amount") },
    { label: t("invoice") },
  ];

  const [modalActive, setModalActive] = useState(false);

  const { payments, isFetching } = useRecurringChargePayments(
    recurringChargeId,
    10,
  );
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    payments.map((payment: RecurringChargePayment) => ({ ...payment })),
  );
  const deleteRecurringPaymentCharges =
    useDeleteRecurringPaymentCharges(recurringChargeId);

  const rowMarkup = payments.map(
    (recurringChargePayment: RecurringChargePayment, index) => (
      <IndexTable.Row
        id={recurringChargePayment.id as string}
        key={recurringChargePayment.id}
        position={index}
        selected={selectedResources.includes(
          recurringChargePayment.id as string,
        )}
      >
        <IndexTable.Cell>
          {formatDate(recurringChargePayment.date, true)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatMoney(recurringChargePayment.amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {recurringChargePayment.invoice_file_public_url ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              url={recurringChargePayment.invoice_file_public_url}
              target="_blank"
            >
              Invoice
            </Link>
          ) : (
            "-"
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const handleDelete = async () => {
    onShowToast("Deleting Charges", false);

    try {
      await deleteRecurringPaymentCharges(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.recurring_charges,
        recurringChargeId,
      ]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("payments")}
            </Text>
            <Button variant="plain" onClick={() => setModalActive(true)}>
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("payment"),
            plural: t("payments"),
          }}
          itemCount={payments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          bulkActions={[
            {
              icon: DeleteIcon,
              destructive: true,
              content: t("delete_payments"),
              onAction: handleDelete,
            },
          ]}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <NewRecurringChargePaymentModal
        recurringChargeId={recurringChargeId as string}
        active={modalActive}
        setActive={setModalActive}
      />
    </Card>
  );
}
