import { Controller } from "react-hook-form";
import { Checkbox } from "@shopify/polaris";

interface Props {
  control: any;
  name: string;
  label: string;
}

export default function CheckboxInput({ control, name, label }: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <Checkbox
          error={errors[name]?.message as any}
          label={label}
          onChange={onChange}
          checked={value}
        />
      )}
    />
  );
}
