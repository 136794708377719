import { Controller } from "react-hook-form";
import { TextField } from "@shopify/polaris";

interface Props {
  control: any;
  name: string;
  label: string;
  type?: any;
  suffix?: any;
  multiline?: any;
  min?: any;
  max?: any;
  disabled?: boolean;
  maxHeight?: string | number | undefined;
}

export default function TextFieldInput({
  control,
  name,
  label,
  type = "text",
  suffix = null,
  multiline = null,
  min = null,
  max = null,
  disabled = false,
  maxHeight = undefined,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <TextField
          type={type}
          suffix={suffix}
          multiline={multiline}
          error={errors[name]?.message as any}
          label={label}
          autoComplete={name}
          onChange={onChange}
          value={value}
          min={min}
          max={max}
          disabled={disabled}
          maxHeight={maxHeight}
        />
      )}
    />
  );
}
