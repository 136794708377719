import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteRentPayments(
  rentId: string,
  ids: string[],
): Promise<void> {
  await axiosInstance.delete(`/rents/${rentId}/payments?ids=${ids.join(",")}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteRentPayments(
  rentId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    deleteRentPayments(rentId, ids),
  );

  return mutateAsync;
}
