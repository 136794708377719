import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { AgentPayment } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useAddAgentPayment } from "./hooks/useAddAgentPayment";
import SelectInput from "../../../../../componenets/SelectInput";

interface Props {
  agentId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    amount: yup.number().required(),
    date: yup.date().required(),
    type: yup.string().required(),
  })
  .required();

export default function AddMonthlyPaymentModal({
  agentId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { control, reset, handleSubmit } = useForm<AgentPayment>({
    defaultValues: {
      date: new Date(),
      type: "SALARY",
    },
    resolver: yupResolver(schema),
  });
  const addAgentPayment = useAddAgentPayment(agentId);

  const handleClose = () => {
    reset({
      amount: 0,
      date: new Date(),
      type: "SALARY",
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (payment: AgentPayment) => {
    setLoading(true);

    try {
      await addAgentPayment(payment);

      await queryClient.invalidateQueries([
        queryKeysConstants.agents,
        agentId,
        "payments",
      ]);

      handleClose();

      onShowToast("Agent Payment Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      title={t("add_new_agent_payment")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput control={control} name="amount" label={t("amount")} />
          <DatePickerInput control={control} name="date" label={t("date")} />
          <SelectInput
            control={control}
            name="type"
            label={t("type")}
            options={[
              {
                label: t("salary"),
                value: "SALARY",
              },
              {
                label: t("insurance"),
                value: "INSURANCE",
              },
            ]}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
