import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { RentPayment } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addRentPayment(payment: RentPayment): Promise<RentPayment> {
  return axiosInstance.post(`/rents/${payment.rent_id}/payments`, payment, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}
export function useAddRentPayment(): UseMutateAsyncFunction<
  RentPayment,
  unknown,
  RentPayment,
  unknown
> {
  const { mutateAsync } = useMutation((payment: RentPayment) =>
    addRentPayment(payment),
  );

  return mutateAsync;
}
