import {
  Card,
  IndexFilters,
  IndexTable,
  InlineStack,
  Page,
  Pagination,
  TabProps,
  Text,
  useSetIndexFiltersMode,
  Link,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { useCars } from "./hooks/useCars";
import { Car } from "../../../types/common.types";
import NewCarModal from "./NewCarModal";
import { formatMoney } from "../../../helpers/helpers";

export default function CarsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [carModalActive, setCarModalActive] = useState(false);
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("active");
  const [queryValue, setQueryValue] = useState("");
  const [query] = useDebounce([queryValue], 1000);

  const { minPage, maxPage, isFetching, page, setPage, cars } = useCars(
    10,
    status,
    query,
  );

  const tabs: TabProps[] = [
    {
      id: "all",
      content: t("all"),
      isLocked: true,
    },
  ];
  const columns = [
    { label: t("car_name") },
    { label: t("car_license_plate") },
    { label: t("documents_count") },
    { label: t("monthly_payment") },
    { label: t("average_monthly_cost") },
    { label: t("min_daily_rate") },
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const rowMarkup = cars.map((car: Car, index) => (
    <IndexTable.Row id={car.id as string} key={car.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink onClick={() => navigate(`/admin/cars/${car.id}`)}>
          <Text fontWeight="bold" as="span">
            {car.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{car.license_plate}</IndexTable.Cell>
      <IndexTable.Cell>{car.documents_count}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.monthly_payment)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.average_monthly_cost)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.min_daily_rate)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("cars")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setCarModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          mode={mode}
          setMode={setMode}
          filters={[]}
          queryValue={queryValue}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          onClearAll={() => {}}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          cancelAction={{
            onAction: () => setQueryValue(""),
          }}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("car"),
            plural: t("cars"),
          }}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={cars.length}
          loading={isFetching}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page !== minPage}
              hasNext={page !== maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
      <NewCarModal active={carModalActive} setActive={setCarModalActive} />
    </Page>
  );
}
