import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Charge } from "../../../../types/common.types";

async function addCharge(charge: Charge): Promise<Charge> {
  return axiosInstance.post(`/charges/`, charge, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddCharge(): UseMutateAsyncFunction<
  Charge,
  unknown,
  Charge,
  unknown
> {
  const { mutateAsync } = useMutation((charge: Charge) => addCharge(charge));

  return mutateAsync;
}
