import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteCustomer(customerId: string): Promise<void> {
  return axiosInstance.delete(`/customers/${customerId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteCustomer(): UseMutateAsyncFunction<
  void,
  unknown,
  string,
  unknown
> {
  const { mutateAsync } = useMutation((customerId: string) =>
    deleteCustomer(customerId),
  );

  return mutateAsync;
}
