import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { InsurancePayment } from "../../../types/common.types";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import DatePickerInput from "../../../componenets/DatePickerInput";
import SelectInput from "../../../componenets/SelectInput";
import { useAddOrEditInsurancePayment } from "./hooks/useAddOrEditInsurancePayment";

interface Props {
  active: boolean;
  setActive: (status: boolean) => void;
  payment: InsurancePayment | null;
}

const schema = yup
  .object({
    amount: yup.number().min(1).required(),
    date: yup.date().required(),
    status: yup.string().required(),
  })
  .required();

export default function NewInsurancePaymentModal({
  active,
  setActive,
  payment,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<InsurancePayment>({
    defaultValues: {
      status: payment?.status || "PENDING",
      amount: payment?.amount || 1,
      date: payment?.date || new Date(),
    },
    resolver: yupResolver(schema),
  });
  const addOrEditPayment = useAddOrEditInsurancePayment(!!payment);

  const handleClose = () => {
    reset({
      amount: 1,
      status: "PENDING",
      date: new Date(),
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (_payment: InsurancePayment) => {
    setLoading(true);

    try {
      const localPayment = {
        ..._payment,
        id: payment?.id || null,
      };
      await addOrEditPayment(localPayment);
      await queryClient.invalidateQueries([queryKeysConstants.insurance]);

      handleClose();

      onShowToast(`Payment ${payment ? "Edited" : "Added"}`, false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    reset({
      status: payment?.status || "PENDING",
      amount: payment?.amount || 1,
      date: payment?.date || new Date(),
    });
  }, [payment]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={payment ? t("edit_payment") : t("new_payment")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label={t("amount")}
            name="amount"
            type="number"
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
          <SelectInput
            control={control}
            name="status"
            label={t("status")}
            options={[
              {
                label: t("pending"),
                value: "PENDING",
              },
              {
                label: t("paid"),
                value: "PAID",
              },
            ]}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
