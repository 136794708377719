import { Frame, Navigation } from "@shopify/polaris";
import {
  PersonFilledIcon,
  ContractFilledIcon,
  CashDollarFilledIcon,
  ChartVerticalFilledIcon,
  DeliveryFilledIcon,
  ShieldCheckMarkIcon,
  RefreshIcon,
  FileFilledIcon,
  SettingsIcon,
  PersonRemoveIcon,
} from "@shopify/polaris-icons";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import logo from "../config/logo";
import Header from "../componenets/Header";
import { ToastAlert } from "../componenets/ToastAlert";
import CarsPage from "../pages/AdminDashboard/Cars/CarsPage";
import RentsPage from "../pages/AdminDashboard/Rents/RentsPage";
import NewRentPage from "../pages/AdminDashboard/Rents/NewRentPage";
import CarPage from "../pages/AdminDashboard/Cars/CarPage";
import CustomersPage from "../pages/AdminDashboard/Customers/CustomersPage";
import NewCustomerPage from "../pages/AdminDashboard/Customers/NewCustomerPage";
import CustomerPage from "../pages/AdminDashboard/Customers/CustomerPage";
import RentPage from "../pages/AdminDashboard/Rents/RentPage";
import ReservationsPage from "../pages/AdminDashboard/Reservations/ReservationsPage";
import ChargesPage from "../pages/AdminDashboard/Charges/ChargesPage";
import InsurancePage from "../pages/AdminDashboard/Insurance/Insurance";
import RecurringChargesPage from "../pages/AdminDashboard/RecurringCharges/RecurringChargesPage";
import RecurringChargePage from "../pages/AdminDashboard/RecurringCharges/RecurringChargePage";
import AgentsPage from "../pages/AdminDashboard/Agents/AgentsPage";
import AgentPage from "../pages/AdminDashboard/Agents/AgentPage";
import MonthlyStatsPage from "../pages/AdminDashboard/Analytics/MonthlyStatsPage";
import OverAllStatsPage from "../pages/AdminDashboard/Analytics/OverAllStatsPage";
import SettingsPage from "../pages/AdminDashboard/Settings/SettingsPage";
import BlacklistPage from "../pages/AdminDashboard/Blacklist/BlacklistPage";

export default function AdminDashboard() {
  const location = useLocation();
  const { t } = useTranslation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const onMobileNavigationToggle = () => {
    setShowMobileNavigation(!showMobileNavigation);
  };

  const onHideMobileNavigation = () => {
    setShowMobileNavigation(false);
  };

  const navigationMarkup = (
    <Navigation onDismiss={onHideMobileNavigation} location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: "/admin/analytics",
            label: t("analytics"),
            icon: ChartVerticalFilledIcon,
            subNavigationItems: [
              {
                url: "/admin/analytics/monthly",
                label: t("monthly_reports"),
              },
            ],
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/cars",
            label: t("cars"),
            icon: DeliveryFilledIcon,
          },
          {
            url: "/admin/customers",
            label: t("customers"),
            icon: PersonFilledIcon,
          },
          {
            url: "/admin/rents",
            label: t("rents"),
            icon: ContractFilledIcon,
          },
          {
            url: "/admin/reservations",
            label: t("reservations"),
            icon: FileFilledIcon,
          },
          {
            url: "/admin/charges",
            label: t("charges"),
            icon: CashDollarFilledIcon,
          },
        ]}
      />
      <Navigation.Section
        fill
        items={[
          {
            url: "/admin/insurance",
            label: t("insurance"),
            icon: ShieldCheckMarkIcon,
          },
          {
            url: "/admin/recurring_charges",
            label: t("recurring_charges"),
            icon: RefreshIcon,
          },
          {
            url: "/admin/agents",
            label: t("agents"),
            icon: PersonFilledIcon,
          },
          {
            url: "/admin/blacklist",
            label: t("blacklist"),
            icon: PersonRemoveIcon,
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            url: "/admin/settings",
            label: t("settings"),
            icon: SettingsIcon,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame
      logo={logo}
      topBar={<Header onNavigationToggle={onMobileNavigationToggle} />}
      navigation={navigationMarkup}
      showMobileNavigation={showMobileNavigation}
      onNavigationDismiss={onHideMobileNavigation}
    >
      <Routes>
        <Route path="/analytics" element={<OverAllStatsPage />} />
        <Route path="/analytics/monthly" element={<MonthlyStatsPage />} />

        <Route path="/cars" element={<CarsPage />} />
        <Route path="/cars/:carId" element={<CarPage />} />

        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/customers/new" element={<NewCustomerPage />} />
        <Route path="/customers/:customerId" element={<CustomerPage />} />

        <Route path="/reservations" element={<ReservationsPage />} />

        <Route path="/rents" element={<RentsPage />} />
        <Route path="/rents/new" element={<NewRentPage />} />
        <Route path="/rents/:rentId" element={<RentPage />} />

        <Route path="/charges" element={<ChargesPage />} />

        <Route path="/insurance" element={<InsurancePage />} />

        <Route path="/recurring_charges" element={<RecurringChargesPage />} />
        <Route
          path="/recurring_charges/:recurringChargeId"
          element={<RecurringChargePage />}
        />

        <Route path="/agents" element={<AgentsPage />} />
        <Route path="/agents/:agentId" element={<AgentPage />} />

        <Route path="/blacklist" element={<BlacklistPage />} />

        <Route path="/settings" element={<SettingsPage />} />

        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <ToastAlert />
    </Frame>
  );
}
