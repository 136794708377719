import { useQuery } from "@tanstack/react-query";
import { Customer } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getCustomer(customerId: string): Promise<Customer> {
  const { data } = await axiosInstance.get(`/customers/${customerId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface Props {
  isLoading: boolean;
  isError: boolean;
  customer: Customer | undefined;
}

export function useCustomer(customerId: string): Props {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.customers, customerId],
    () => getCustomer(customerId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    customer: data,
  };
}
