import { BlockStack, Card, Text } from "@shopify/polaris";
import { DonutChart } from "@shopify/polaris-viz";
import React, { type ReactNode } from "react";
import { InnerValueContents } from "@shopify/polaris-viz/build/ts/types";
import { useTranslation } from "react-i18next";
import { ChartState } from "@shopify/polaris-viz-core/build/ts/types";
import { formatMoney } from "../../../../helpers/helpers";
import { MonthlyStats } from "../../../../types/common.types";

export default function ChargesRevenueChart({
  stats,
  isLoading,
}: {
  stats: MonthlyStats;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  const handleRenderValueContent = (values: InnerValueContents): ReactNode => {
    const totalCharges = stats?.total_charges_in_current_month || 0;
    const totalMade = stats?.revenue_in_current_month || 0;

    if (values.activeIndex === -1) {
      return `${formatMoney(totalMade - totalCharges)}`;
    }

    return formatMoney(values.activeValue || 0);
  };

  return (
    <Card>
      <BlockStack gap="800">
        <Text as="h2" variant="bodySm">
          Charges vs gross revenue in current month
        </Text>

        <div style={{ height: "20rem" }}>
          <DonutChart
            showLegend
            legendPosition="bottom"
            showLegendValues
            data={[
              {
                name: t("total_charges"),
                data: [
                  {
                    key: "total_charges",
                    value: stats?.total_charges_in_current_month || 0,
                  },
                ],
              },
              {
                name: "Gross Revenue",
                data: [
                  {
                    key: "gross_revenue",
                    value: stats?.revenue_in_current_month || 0,
                  },
                ],
              },
            ]}
            theme="Light"
            labelFormatter={(value) => formatMoney(value as number)}
            renderInnerValueContent={handleRenderValueContent}
            state={
              isLoading ? ("Loading" as ChartState) : ("Success" as ChartState)
            }
          />
        </div>
      </BlockStack>
    </Card>
  );
}
